/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CLandingpageMediaKey {
    HEADER = 'Header',
    GIMMICK = 'Gimmick',
    SQUARE = 'Square',
    BACKGROUND = 'Background',
}
