import { TranslationService, TranslationsGetResponse } from 'src/apis/api-client';
import axios, { AxiosRequestConfig } from 'axios';
import i18next, { t } from 'i18next';
import HttpBackend from 'i18next-http-backend';

// Import translations.
import translationsGerman from 'src/assets/translations/de/translations.json';
import translationsEnglish from 'src/assets/translations/en/translations.json';

const resources = {
  de: {
    translation: translationsGerman
  },
  en: {
    translation: translationsEnglish
  }
}

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

/* Loads the translations and sets them to the resourceBundle. */
export async function loadTranslation() {

  /* Add the static translation files to the resource bundle. */
  Object.entries(resources).forEach(([language, namespaces]) => {
    Object.entries(namespaces).forEach(([namespace, translations]) => {
      i18next.addResourceBundle(language, namespace, translations, true, true);
    });
  });

  /* Get the translation file from the backend and add it to the resource bundle. */
  const response: TranslationsGetResponse
    = await TranslationService.get();

  if (response.translations) {
    i18next.addResourceBundle('backend', 'translation', response.translations);
  }
}

/* Configures i18next. Translations are loaded from the backend and the default language is set. 
Also the fallback languages are set and the methods for interpolation are defined. */
i18next
  .use(HttpBackend)
  .init({
    resources: resources,
    lng: navigator.language, // Browser language of the user.
    fallbackLng: ['en', 'backend'],
    interpolation: {
      format: interpolationFormat,
    },
  });

export function interpolationFormat(value: any, format?: string, lng?: string) {
  if (format == 'datetime' || value instanceof Date) {
    if (lng == 'de') {
      return new Intl.DateTimeFormat(
        'de',
        {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hourCycle: 'h24',
          hour: '2-digit',
          minute: '2-digit'
        }
      ).format(value);
    }
    else if (lng == 'en') {
      return new Intl.DateTimeFormat(
        'en',
        {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hourCycle: 'h12',
          hour: '2-digit',
          minute: '2-digit'
        }
      ).format(value);
    }
  }

  if (format == 'points' || format == 'signlessPoints') {
    return new Intl.NumberFormat(
      lng,
      {
        compactDisplay: 'long',
        signDisplay: format == 'signlessPoints' ? 'auto' : 'always'
      }
    )
      .format(value);
  }


  return value;
}

/**
 *
 * @param value
 * @param language
 * @param withTime
 */
export function formatDateSpecific(
  value: string,
  language: string,
  withTime = true
) {
  if (withTime) {
    return Intl.DateTimeFormat(
      language,
      {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }
    )
      .format(Date.parse(value));
  } else {
    return Intl.DateTimeFormat(
      language,
      {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: undefined,
        minute: undefined
      }
    )
      .format(Date.parse(value));
  }
}

export function setupLanguageHeader() {
  axios.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      if (config.headers)
        config.headers['X-Language'] = i18next.language;
      return config;
    },
    error => {
      void Promise.reject(error);
    },
  );
}

/* Change the language of i18next. Currently available en, de */
export function changeLanguage(languageCode: string) {
  i18next.changeLanguage(languageCode);
  return i18next.language;
}

/* Checks if the requested valueKey is available in the current backendResource.
If it is not, the original value (de) is displayed. */
export function isValid(translationKey: string): boolean {

  const backendResources: Record<string, Record<string, string>>
    = i18next.getResourceBundle('backend', 'translation');

  if (backendResources === undefined) {
    loadTranslation();
  } else {
    const value = backendResources[i18next.language];
    for (const keyValue in value) {
      if (keyValue == translationKey) {
        return true;
      }
    }
  }

  return false;
}

/*
  Validates the transferred key.
  Returns the correct translation key in the true case.
  If false, a empty string is returned.
*/
export function getTranslationKey(id: string, propertyKey: string): string {
  const validationKey = `${id}_${propertyKey.toLowerCase()}`;

  if (isValid(validationKey)) {
    return `${i18next.language}.${id}_${propertyKey}`.toLowerCase();
  } else {
    return '';
  }
}

/**
 * Constructs the translation key.
 * @param id The id to use as the contentId.
 * @param entityName The name of the entity.
 * @param propertyName The name of the property.
 * @returns The translation key normalized.
 */
export function getConstructedTranslationKey(
  id: string,
  entityName: string,
  propertyName: string
): string {
  return getTranslationKey(id, `${entityName}_${propertyName}`);
}

/**
 * Translates by the given translation data.
 * @param id The id to use as the contentId.
 * @param entityName The name of the entity.
 * @param propertyName The name of the property.
 * @param defaultValue The default value to use.
 * @returns The translated string.
 */
export function tte(
  id: string,
  entityName: string,
  propertyName: string,
  defaultValue: string
): string {
  return t(getConstructedTranslationKey(id, entityName, propertyName), defaultValue);
}

/**
 * Translates the given property key.
 * @param id The id to use as the contentId.
 * @param propertyKey The name of the property.
 * @param defaultValue The default value to use.
 * @returns The translated string.
 */
export function tt(id: string, propertyKey: string, defaultValue: string): string {
  return t(getTranslationKey(id, propertyKey)) || defaultValue;
}

/**
 * Formats the date.
 * @param value The date string to format.
 * @param withTime Flag, if the time should also be formatted and added.
 * @returns The formated date.
 */
export function fd(value: string, withTime = true): string {
  return formatDateSpecific(value, i18next.language, withTime);
}