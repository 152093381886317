import { Card } from 'primereact/card';
import { PropsWithChildren } from 'react';
import { Skeleton } from 'primereact/skeleton';
import { Tag } from 'primereact/tag';
import { CPurchaseStatus } from 'src/apis/api-client';
import { t } from 'i18next';
import {Button} from "primereact/button";

export type FiestaListCardProps = {
  image: string;
  logo?: string;
  title: string;
  subTitle?: string;
  textContent?: string;
  status?: CPurchaseStatus;
  responsive?: boolean;
  loading: boolean;
}

export function FiestaListCard(props: PropsWithChildren<FiestaListCardProps>) {

  /* Import variables from props. */
  const {
    image,
    logo,
    title,
    subTitle,
    textContent,
    status,
    children,
    loading,
    responsive
  } = props;

  /**
   * Renders the image.
   * @param width The width to apply.
   * @param logoUrl The url of the logo.
   * @param classAddition The class to add.
   * @returns Rendered image block.
   */
  function renderImage(
    width: number,
    logo: string,
    classAddition: string
  ) {
    return <>
      {
        logo
        &&
        <div
          style={
            {
              width: width,
              height: width,
              backgroundColor: 'white!important',
            }
          }
          className={`border-round-xl shadow-6 ${classAddition}`}
        >
          {
            loading
              ? <Skeleton width='128px' height='128px' />
              : <img
                  src={logo}
                  width={`${width}px`}
                  height={`${width}px`}
                  style={{ backgroundColor: 'white' }}
                  className='border-round-lg'
                />
          }
        </div>
      }
    </>;
  }

  /**
   * Renders the logo box in the middle of the image header.
   * @returns div with logo contained in it.
   */
  function renderHeaderContent() {
    return (
      <div className='flex flex-row'>
        {
          logo
          &&
          renderImage(148, logo, 'hidden md:block')
        }
        {
          logo
          &&
          renderImage(100, logo, 'block md:hidden')
        }
        <div className={`flex flex-column justify-content-center ${logo ? 'pl-2' : ''}`}>
          <div className={!status ? 'text-900 text-3xl font-medium' : 'text-900 text-lg justify-content-between font-medium flex'}>
            {
              loading
                ? <Skeleton height='32px' />
                : title
            }
          </div>
          <div className='text-600 font-medium mt-2'>
            <span>
              {
                loading
                  ? <Skeleton height='22px' width='50%' className='mt-2' />
                  : subTitle
              }
            </span>
          </div>
          {
            loading
              ? <div>
                <Skeleton height='12px' width='75%' className='mt-2' />
                <Skeleton height='12px' width='75%' className='mt-2' />
                <Skeleton height='12px' width='75%' className='mt-2' />
              </div>
              : (
                textContent
                &&
                <p className={!status ? 'text-700 text-normal' : 'text-700 text-normal mt-0'}>
                  {
                    textContent
                  }
                </p>
              )
          }
        </div>
      </div>
    );
  }

  return (
    <Card
      className='w-full mt-4 shadow-none border-round-lg h-full fiesta-card'
    >
      <div className='grid h-full'>
        <div
          className={`${responsive == true ? 'lg:col-6' : ''} col-12`}
          style={
            {
              paddingTop: 0,
              paddingBottom: 0,
              height: '200px'
            }
          }
        >
          {
            !loading
              ?
              <div
                style={
                  {
                    width: '100%',
                    height: '100%',
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75),rgba(0, 0, 0, 0.5)),url(${image})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    minHeight: '148px',
                    paddingTop: '24px',
                    paddingBottom: '24px',
                    paddingLeft: '24px',
                  }
                }
                className='border-round-lg'
              >
                {
                  renderHeaderContent()
                }
              </div>
              :
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: '#dee2e6',
                  minHeight: '170px'
                }}
                className='border-round-lg'
              />
          }
        </div>
        <div
          className={`${responsive == true ? 'lg:col-6' : ''} col-12 pl-4 pr-4 pt-4`}
        >
          {
            !loading
              ? children
              : <></>
          }
        </div>
      </div>
    </Card>
  );
}

export default FiestaListCard;
