/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GenresGetResponse } from '../models/GenresGetResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GenresService {

    /**
     * @param searchString
     * @param skip
     * @param take
     * @returns GenresGetResponse OK
     * @throws ApiError
     */
    public static get(
        searchString?: string,
        skip?: number,
        take: number = 25,
    ): CancelablePromise<GenresGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Genres',
            query: {
                'searchString': searchString,
                'skip': skip,
                'take': take,
            },
        });
    }

}
