import { Avatar } from 'primereact/avatar';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import { CProfileSocialMedia, CSocialMedia, MeService, ProfileSocialMediaGetResponse, ProfilesGetDetailResponse, ProfilesGetDetailResponse_CProfile, SocialMediaGetResponse, SocialMediaService } from 'src/apis/api-client';

export type ProfileShortViewProps = {
  profileId?: string;
  showSocials: boolean;
  name?: string | undefined | null;
  itsYou?: boolean;
  location?: string | undefined | null;
  textColor?: 'black' | null;
}

export function ProfileShortView(props: PropsWithChildren<ProfileShortViewProps>) {

  /* Import variables from props. */
  const { profileId, showSocials, name, itsYou, location, textColor } = props;

  const navigate = useNavigate();

  const [ profileDetails, setProfileDetails ] = useState<ProfilesGetDetailResponse_CProfile>();
  const [ profileSocialMedia, setProfileSocialMedia ] = useState<CProfileSocialMedia[]>([]);
  const [ socialMedia, setSocialMedia ] = useState<CSocialMedia[]>([]);

  /* Fetch data for the profile */
  async function fetchProfileDetails() {
    if (profileId) {
      const response: ProfilesGetDetailResponse
        = await MeService.getDetail(profileId);

      if (response.data) {
        setProfileDetails(response.data);
      }

      return response;
    }
  }

  /* Fetch all data for the profile social media (not used atm) */
  async function fetchProfileSocialMedia() {
    if (profileId) {
      const response: ProfileSocialMediaGetResponse
        = await MeService.getSocialMedia(profileId);

      if (response.result) {
        setProfileSocialMedia(response.result);
      }

      return response;
    }
  }

  /* Get all social media channels */
  async function fetchSocialMedia() {
    const response: SocialMediaGetResponse
      = await SocialMediaService.get();

    if (response.result) {
      setSocialMedia(response.result);
    }
    return response;
  }

  /* Called with creating of this component to fetch all data for a profile */
  useEffect(
    () => {
      (
        async () => {
          await fetchProfileDetails();
          await fetchProfileSocialMedia();
          await fetchSocialMedia();

          if (name) {
            setProfileDetails({ name: name, profilePicture: '', location: location })
          }
        }
      )();
    },
    []
  );

  /**
   * Generates a link to a user's profile on various social media platforms.
   * @param socialMediaName The name of the social media platform (e.g., 'Facebook', 'TikTok', 'Instagram', 'Snapchat', 'Spotify').
   * @param userTag The user's tag or identifier on the social media platform.
   * @returns The generated URL link to the user's profile on the specified social media platform.
   */
  function openLink(socialMediaName: string, userTag: string) {
    switch (socialMediaName) {
      case 'Facebook':
        return `https://${socialMediaName}.com/${userTag}`;

      case 'TikTok':
        return `https://${socialMediaName}.com/@${userTag}`;

      case 'Instagram':
        return `https://${socialMediaName}.com/${userTag}`;

      case 'Snapchat':
        return `https://${socialMediaName}.com/add/${userTag}`;

      case 'Spotify':
        if (userTag.startsWith('https://')) {
          return userTag;
        } else {
          return `https://${userTag}`;
        }
    }
  }

  function openProfile() {
    if (profileId !== undefined) {
      if (itsYou) {
        navigate('/profile')
      } else {
        navigate(`/profile/${profileId}`)
      }
    }
  }

  function renderSocialMedia() {
    return socialMedia.map(
      (e) => (
        profileSocialMedia.map(
          (ps) => (
            ps.socialMediaId == e.id
            && ps.userTag != ''
            && e 
            &&
            <SocialIcon
              network={e.name ? e.name.toLocaleLowerCase() : ''}
              onClick={
                () => window.open(openLink(e.name!, ps.userTag!), '_blank')
              }
              style={{ height: 25, width: 25 }}
            />
          )
        )
      )
    );
  }

  /* Returns the short view of the user profile */
  return (
    <div className='flex w-full justify-content-between align-items-center'>
      <div className='flex'>
        <div>
          {
            profileDetails &&
            <Avatar
              image={
                profileDetails.profilePicture
                  ? profileDetails?.profilePicture
                  : ''
              }
              style={{ width: '45px', height: '45px', borderRadius: '50%' }}
              onClick={() => openProfile()}
              imageAlt='Image'
              icon='pi pi-user'
              shape='circle'
              className='mr-3'
            />
          }
        </div>
        <div>
          <span
            className={
              textColor
                ? `block font-medium mb-1 text-${textColor}-900`
                : `block font-medium mb-1 text-900`
            }
          >
            {profileDetails?.name} {profileDetails?.surname}
          </span>
          <span
            className={
              textColor
                ? `text-${textColor}-600`
                : `text-600`
            }
          >
            {profileDetails?.location}
          </span>
        </div>
      </div>
      {
        showSocials
        &&
        <div className='hidden mt-2 md:mt-0 md:flex flex-wrap pl-3 gap-1'>
          {
            renderSocialMedia() 
          }
        </div>
      }
    </div>
  );
}
