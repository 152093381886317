/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RewardMetadataGetResponse } from '../models/RewardMetadataGetResponse';
import type { RewardsGetDetailResponse } from '../models/RewardsGetDetailResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RewardsService {

    /**
     * @param rewardId
     * @returns RewardsGetDetailResponse OK
     * @throws ApiError
     */
    public static getDetail(
        rewardId: string,
    ): CancelablePromise<RewardsGetDetailResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Rewards/{rewardId}',
            path: {
                'rewardId': rewardId,
            },
        });
    }

    /**
     * @param rewardId
     * @returns RewardMetadataGetResponse OK
     * @throws ApiError
     */
    public static getRewardMetadata(
        rewardId: string,
    ): CancelablePromise<RewardMetadataGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Rewards/{rewardId}/Metadata',
            path: {
                'rewardId': rewardId,
            },
        });
    }

}
