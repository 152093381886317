import { CLanguage, LanguagesGetResponse, LanguagesService, MeService, ProfilesGetDetailResponse, ProfilesGetDetailResponse_CProfile } from "src/apis/api-client";
import { FormEvent, PropsWithChildren, useEffect, useState } from "react";
import { Card } from "primereact/card";
import { FiestaFact, changeLanguage } from "src/shared";
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import { InputText } from "primereact/inputtext";
import { Calendar, CalendarViewChangeEvent } from 'primereact/calendar';
import i18next from "i18next";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { t } from 'i18next';
import { useNavigate } from "react-router-dom";

export type ProfileBasicInfoProps = {
  profileId: string;
  readOnly?: boolean;
}

export function ProfileBasicInfo(props: PropsWithChildren<ProfileBasicInfoProps>) {

  const navigate = useNavigate();

  /* Import variables from props. */
  const { profileId, readOnly } = props;

  const [showFestivalInplace] = useState<boolean>(false);
  const [showLocationInplace] = useState<boolean>(false);
  const [showCalendarInplace] = useState<boolean>(false);
  const [profile, setProfile] = useState<ProfilesGetDetailResponse_CProfile | undefined>(undefined);
  const [birthDate, setBirthdate] = useState<string | Date | Date[] | null>();
  const [languages, setLanguages] = useState<CLanguage[] | undefined>(undefined);
  const [selectedLanguage, setSelectedLanguage] = useState<CLanguage | undefined>(undefined);

  /* Gets the profile data */
  async function fetchProfile() {
    const response: ProfilesGetDetailResponse
      = await MeService.getDetail(profileId);

    if (response.data) {
      setProfile(response.data)
      if (response.data.birthDate)
        setBirthdate(response.data.birthDate)
    } else {
      setProfile(undefined);
    }
    return response;
  }

  /* Gets all languages */
  async function fetchLanguages() {
    const response: LanguagesGetResponse
      = await LanguagesService.get();

    if (response.result) {
      setLanguages(response.result);
    }
  }

  /* Called with create of this component to get all profile data and language data */
  useEffect(
    () => {
      (
        async () => {
          await fetchProfile();
          await fetchLanguages();
        }
      )();
    },
    []
  );

  /* Call the put endpoint to change the language of the user */
  useEffect(
    () => {
      (
        async () => {
          if (profile) {
            await MeService.put(profile?.id, { data: {...profile, languageId: selectedLanguage?.id } })
            await fetchProfile();
            if (selectedLanguage?.code) {
              changeLanguage(selectedLanguage?.code);
              navigate(window.location);
            }
          }
        }
      )();
    },
    [selectedLanguage]
  );

  /* Update the language in the database */
  async function putProfile() {
    if (profile?.id) {
      await MeService.put(profile.id, { data: profile })
    }
  }

  /* Returns the whole basic infos of the profile */
  return (
    <div className='grid pt-3'>
      <div className='col-12 md:col-3'>
        <Card
          className='flex flex-1 p-4 text-center shadow-none justify-content-center align-items-center h-full'
        >
          <FiestaFact
            title={t('profile.components.profileBasicInfo.festivalCardTitle')}
            className=''
            loading={false}
          >
            {
              !readOnly ?
                <Inplace
                  active={showFestivalInplace}
                  onClose={() => putProfile()}
                  closable
                  closeIcon='pi pi-check'
                  className="">
                  <InplaceDisplay>
                    <span
                      className='text-xl font-bold'
                    >
                      {
                        profile?.favouriteFestival || <i className="pi pi-pencil" />
                      }
                    </span>
                  </InplaceDisplay>
                  <InplaceContent>
                    {
                      <InputText maxLength={32} className="lg:w-9 w-16rem" value={profile?.favouriteFestival ? profile.favouriteFestival : ''} onChange={(e) => { setProfile({ ...profile, favouriteFestival: e.target.value }) }} autoFocus />
                    }
                  </InplaceContent>
                </Inplace>
                :
                <span
                  className='text-xl font-bold'
                >
                  {
                    profile?.favouriteFestival || '-'
                  }
                </span>
            }
          </FiestaFact>
        </Card>
      </div>
      <div className='col-12 md:col-3'>
        <Card
          className='flex flex-1 p-4 text-center shadow-none justify-content-center align-items-center h-full'
        >

          {
            !readOnly ?
              <FiestaFact
                title={t('profile.components.profileBasicInfo.birthdayCardTitle')}
                className=''
                loading={false}
              >
                <Inplace
                  active={showCalendarInplace}
                  onClose={() => { putProfile() }}
                  closable
                  closeIcon='pi pi-check'>
                  <InplaceDisplay>
                    <span
                      className='text-xl font-bold'
                    >
                      {
                        profile?.birthDate ?
                          Intl.DateTimeFormat(i18next.language, { day: "2-digit", month: "2-digit", year: "numeric" }).format(Date.parse(profile?.birthDate)) : <i className="pi pi-pencil" />
                      }
                    </span>
                  </InplaceDisplay>
                  <InplaceContent>
                    {
                      <Calendar
                        className="lg:w-9 w-16rem"
                        dateFormat={'dd.mm.yy'}
                        value={new Date(birthDate as Date) || ''}
                        
                        onChange={
                          (e) => {
                            setProfile({ ...profile, birthDate: e.value?.toISOString() });
                            setBirthdate(e.value);
                          }
                        }
                      >
                      </Calendar>
                    }
                  </InplaceContent>
                </Inplace>
              </FiestaFact>
              :
              <FiestaFact
                title={t('profile.components.profileBasicInfo.ageCardTitle')}
                className=""
                loading={false}
              >
                {
                  <span
                    className='text-xl font-bold'
                  >
                    {
                      profile?.age &&
                      profile?.age || '-'
                    }
                  </span>
                }
              </FiestaFact>
          }
        </Card>
      </div>
      <div className='col-12 md:col-3'>
        <Card
          className='flex flex-1 p-4 text-center shadow-none justify-content-center align-items-center h-full'
        >
          <FiestaFact
            title={t('profile.components.profileBasicInfo.locationCardTitle')}
            className=''
            loading={false}
          >
            {
              !readOnly ?
                <Inplace
                  active={showLocationInplace}
                  onClose={() => { putProfile() }}
                  closable
                  closeIcon='pi pi-check'>
                  <InplaceDisplay>
                    <span
                      className='text-xl font-bold'
                    >
                      {
                        profile?.location || <i className="pi pi-pencil" />
                      }
                    </span>
                  </InplaceDisplay>
                  <InplaceContent>
                    {
                      <InputText className="lg:w-9 w-16rem" maxLength={32} value={profile?.location ? profile.location : ''} onChange={(e) => { setProfile({ ...profile, location: e.target.value }) }} autoFocus />
                    }
                  </InplaceContent>
                </Inplace>
                :
                <span className='text-xl font-bold'>
                  {
                    profile?.location || '-'
                  }
                </span>
            }
          </FiestaFact>
        </Card>
      </div>
      <div className='col-12 md:col-3'>
        <Card
          className='flex flex-1 p-4 text-center shadow-none justify-content-center align-items-center h-full'
        >
          {
            !readOnly ?
              <FiestaFact
                title={t('profile.components.profileBasicInfo.languageCardTitle')}
                className=''
                loading={false}
              >
                <Inplace closable closeIcon='pi pi-check'>
                  <InplaceDisplay>
                    <span
                      className='text-xl font-bold'
                    >
                      {
                        profile?.languageId &&
                        languages?.find((e) => e.id == profile.languageId)?.name || <i className="pi pi-pencil" />

                      }
                    </span>
                  </InplaceDisplay>
                  <InplaceContent>
                    {
                      <Dropdown
                        className="lg:w-8 w-16rem"
                        options={languages}
                        optionLabel="name"
                        value={selectedLanguage || profile && languages?.find((e) => e.id == profile.languageId)}
                        placeholder="Auswählen/Select"
                        onChange={
                          (e: DropdownChangeEvent) => {
                            setSelectedLanguage(e.value);
                          }
                        }
                      />
                    }
                  </InplaceContent>
                </Inplace>
              </FiestaFact>
              :
              <FiestaFact
                title={t('profile.components.profileBasicInfo.ambassadorSinceCard')}
                loading={false}
                className="">
                <span className="text-xl font-bold">
                  {
                    profile?.createdOn &&
                    Intl.DateTimeFormat(i18next.language, { day: "2-digit", month: "2-digit", year: "numeric" }).format(Date.parse(profile?.createdOn))
                  }
                </span>
              </FiestaFact>
          }
        </Card>
      </div>
    </div>

  );
}
