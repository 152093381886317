import { Checkbox } from 'primereact/checkbox';
import { Steps } from 'primereact/steps';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ActionsService, CPurchaseStatus, CRewardMetadata, EventsGetDetailResponse, EventsGetResponse_CEvent, EventsService, ProgramsGetDetailResponse, ProgramsGetDetailResponse_CProgram, ProgramsService, ProgramStatisticsGetPointsResponse, PurchaseRewardRequest, PurchaseRewardResponse, PurchaseRewardResponse_CPurchase, RewardMetadataGetResponse, RewardsGetDetailResponse, RewardsGetDetailResponse_CReward, RewardsService, ShopsGetDetailResponse, ShopsService } from 'src/apis/api-client';
import { FiestaImageHeader } from 'src/shared/components/image-header';

import { t } from 'i18next';
import { Button } from 'primereact/button';
import { getTranslationKey } from 'src/shared';
import FiestaInfiniteSpinner from 'src/shared/components/infinite-spinner/FiestaInfiniteSpinner';
import { MetadataContent, MetadataContent_MetadataValue } from './components';
import './RewardClaimView.css';

export function RewardClaimView() {
  /* Using params, as we are expecting the program id. */
  const routeParams = useParams();
  const navigate = useNavigate();

  const [ loading, setLoading ] = useState<boolean>(true);
  const [ reward, setReward ] = useState<RewardsGetDetailResponse_CReward>();
  const [ program, setProgram ] = useState<ProgramsGetDetailResponse_CProgram>();
  const [ event, setEvent ] = useState<EventsGetResponse_CEvent>();
  const [ points, setPoints ] = useState<ProgramStatisticsGetPointsResponse>();
  const [ metadata, setMetadata ] = useState<CRewardMetadata[]>();

  const [ activeIndex, setActiveIndex ] = useState<number>();

  const [ checkboxed, setCheckboxed ] = useState<boolean>(false);

  const [ metadataValues, setMetadataValues ] = useState<MetadataContent_MetadataValue[]>([]);

  const [ purchaseConfirmation, setPurchaseConfirmation ] = useState<PurchaseRewardResponse_CPurchase>();

  useEffect(
    () => {
      (
        async () => {
          setLoading(true);

          if (!routeParams.rewardId) return;

          const rewardResponse = await fetchReward(routeParams.rewardId);
          if (rewardResponse?.shopId) {
            const metadataResponse = await fetchMetadata(routeParams.rewardId);
            const shopResponse = await fetchShop(rewardResponse.shopId);
            if (shopResponse?.programId) {
              const programResponse = await fetchProgram(shopResponse.programId);
              const pointsResponse = await fetchPoints(shopResponse.programId);
              if (programResponse?.eventId) {
                const eventResponse = await fetchEvent(programResponse.eventId);

                setReward(rewardResponse);
                setProgram(programResponse);
                setEvent(eventResponse);
                setPoints(pointsResponse);

                if (metadataResponse != null && metadataResponse.result != null && metadataResponse.result.length > 0) {
                  setMetadata(metadataResponse?.result);
                  setActiveIndex(0);
                } else {
                  setActiveIndex(1);
                }
              }
            }
          }

          setLoading(false);
        }
      )();
    },
    [ routeParams.rewardId ]
  );

  async function fetchReward(rewardId: string) {
    const response : RewardsGetDetailResponse 
      = await RewardsService.getDetail(rewardId);
    
    return response.data;
  }

  async function fetchShop(shopId: string) {
    const response : ShopsGetDetailResponse
      = await ShopsService
        .getDetail(shopId);

    if (response && response.data)
      return response.data;
  }

  async function fetchProgram(programId: string) {
    const response : ProgramsGetDetailResponse
      = await ProgramsService
        .getDetail(programId);

    if (response && response.data)
      return response.data;
  }

  async function fetchEvent(eventId: string) {
    const response : EventsGetDetailResponse
      = await EventsService
        .getDetail(eventId);

    if (response && response.data)
      return response.data;
  }

  async function fetchPoints(programId: string) {
    const response : ProgramStatisticsGetPointsResponse
      = await ProgramsService
        .getPoints(programId);

    if (response)
      return response;
  }

  async function fetchMetadata(rewardId: string) {
    const response : RewardMetadataGetResponse
      = await RewardsService
        .getRewardMetadata(rewardId);

    if (response)
      return response;
  }

  async function claimReward(rewardId: string) {
    const requestBody : PurchaseRewardRequest = {
      rewardId: rewardId,
      metaValues: []
    }

    metadataValues?.forEach(
      (value) => {
        if (requestBody.metaValues) {
          requestBody.metaValues.push(
            {
              rewardMetadataId: value.id,
              value: value.value
            }
          );
        }
      }
    );

    const response : PurchaseRewardResponse = 
      await ActionsService.purchaseReward(requestBody);

    if (response.data) {
      return response.data;
    }
  }

  function navigateToProgram() {
    navigate('/programs/' + program?.id + '/dashboard');
  }

  function renderMetadata() {
    if (!metadata)
      return;

    return <div className='flex flex-column gap-4'>
      <div className='flex flex-row'>
        <p>
          {
            t('domains.rewards.views.claim.metaIntro')
          }
        </p>
      </div>
      <div className='flex flex-row'>
        <MetadataContent
          rewardMetadata={metadata}
          onConfirm={
            function (values: MetadataContent_MetadataValue[]): void {
              setMetadataValues(values);
              setActiveIndex(1);
            }
          }
        />
      </div>
    </div>;
  }

  function renderOverview() {
    return <div>
      <div className='text-center'>
        {
          points?.availablePoints
          && reward?.price
          &&
          <p
            dangerouslySetInnerHTML={
              {
                __html: 
                  t('domains.rewards.views.claim.summaryText', 
                    { 
                      interpolation: { escapeValue: false },
                      price: reward.price.toLocaleString('de-DE'),
                      pointsLeft: (points.availablePoints - reward.price).toLocaleString('de-DE')
                    }
                  )
              }
            }
          >  
          </p>
        }
        <div className='col-12'>
          <Checkbox 
            inputId='cb1'
            value='New York' 
            onChange={
              (e) => {
                if (e.checked != null)
                  setCheckboxed(e.checked);
              }
            } 
            checked={checkboxed}
          />
          <label 
            htmlFor='cb1'
            className='p-checkbox-label ml-2'
            dangerouslySetInnerHTML={{__html: t('domains.rewards.views.claim.termsText')}}
          />
        </div>
      </div>
      <div
        className='flex flex-column sm:flex-row flex-wrap gap-4 p-4'
      >
        <Button
          className='flex-1'
          label={
            t('domains.rewards.views.claim.buttons.accept')
          }
          disabled={!checkboxed}
          onClick={
            async () => {
              if (reward?.id) {
                setActiveIndex(2);
                const confirmation = await claimReward(reward?.id);
                setPurchaseConfirmation(confirmation);
              }
            }
          }
        />
        <Button
          className='flex-1 p-button-outlined p-button-secondary'
          label={
            t('domains.rewards.views.claim.buttons.abort')
          }
          onClick={navigateToProgram}
        />
      </div>
    </div>;
  }

  function renderConfirmation() {
    return <>
      <div
        className='text-center p-4'
      >
        {
          purchaseConfirmation
          &&
          (
            purchaseConfirmation.status == CPurchaseStatus.CONFIRMED
            || purchaseConfirmation.status == CPurchaseStatus.WAITING
          )
          &&
          <div>
            <i className='pi pi-check-circle text-green-400' style={{'fontSize': '4em'}}></i>
            <div className='text-900 text-3xl font-medium mt-2 mb-0'>
              {
                t(
                  'domains.rewards.views.claim.orderConfirmation.title',
                  {
                    orderNumber: purchaseConfirmation.number?.toString().padStart(6, '0')
                  }
                )
              }
            </div>
            <p
              dangerouslySetInnerHTML={
                {
                  __html: t('domains.rewards.views.claim.orderConfirmation.textContent', 
                    {
                      interpolation: { escapeValue: false } 
                    }
                  )
                }
              }
            />
          </div>
        }
        {
          !purchaseConfirmation
          &&
          <FiestaInfiniteSpinner />
        }
      </div>
      <div
        className='flex flex-column sm:flex-row flex-wrap gap-4 p-4'
      >
        <Button
          className='flex-1 p-button-outlined p-button-secondary'
          label={
            t('domains.rewards.views.claim.orderConfirmation.button')
          }
          onClick={
            () => {
              navigate('/programs/' + program?.id + '/dashboard');
            }
          }
        />
      </div>
    </>;
  }

  return (
    <>
      <FiestaImageHeader
        loading={loading}
        title={
          reward?.title && reward.id
            ? t(getTranslationKey(reward.id, 'reward_title')) || reward.title
            : ''
        }
        subTitle={
          event?.title
          && reward?.price 
          && program?.id 
          && event?.id
            ? `${t(getTranslationKey(event.id, 'event_title')) || event.title} | ${t(getTranslationKey(program.id, 'program_title')) || program.title}`
            : ''
        }
        image={
          reward?.image
            ? reward.image
            : ''
        }
        logo={
          event?.logo
            ? event?.logo
            : ''
        }
      />
      <div className='grid pt-4'>
        {
          loading
          &&
          <div className='col-12'>
            <div className='flex flex-column align-items-center'>
              <FiestaInfiniteSpinner />
            </div>
          </div>
        }
        {
          !loading
          &&
          <div className='col-12'>
            <Steps
              activeIndex={activeIndex}
              onSelect={(e) => { setActiveIndex(e.index); }}
              // className='flex flex-row f-width-tablist w-full'
              model={
                [
                  { label: 'Informations', className: 'flex flex-1' },
                  { label: 'Overview', className: 'flex flex-1' },
                  { label: 'Confirmation', className: 'flex flex-1' }
                ]
              }
            />
            <div className='col-12 lg:col-8 lg:col-offset-2 grid'>
              <div className='col-12'>
                {
                  activeIndex == 0
                    ? renderMetadata()
                    : <></>
                }
                {
                  activeIndex == 1
                    ? renderOverview()
                    : <></>
                }
                {
                  activeIndex == 2
                    ? renderConfirmation()
                    : <></>
                }
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
}

export default RewardClaimView;
