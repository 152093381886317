import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

// Import theme.
import 'src/assets/themes/theme.css';

// MSAL imports.
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, setupApiBearerAuthentication, setupLanguageHeader, setupPayloadIntercept } from 'src/shared';
import { OpenAPI } from './apis/api-client';
import { initializeLocaleSettings } from './shared';

// Import i18n.
import 'src/shared/libraries/i18n';
import { BrowserRouter } from 'react-router-dom';
import { MaintenanceModeView } from 'src/shared/views/MaintenanceModeView';

// Global exports.
export * from './core';
export * from './domains';
export * from './shared';

// MSAL configuration.
export const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

// Add additional configuration based on MSAL.
setupPayloadIntercept(msalInstance);
setupApiBearerAuthentication(msalInstance);

// Locale for api.
initializeLocaleSettings();
setupLanguageHeader();

OpenAPI.BASE = process.env.REACT_APP_API_URL ?? '';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

/**
 * Renders the body.
 * @returns BrowserRouter element.
 */
function renderBody() {
  return <BrowserRouter>
    {
      process.env.REACT_APP_MAINTENANCE_MODE == 'true'
      && document.cookie.indexOf('suppress_maintenance') === -1
        ? <MaintenanceModeView />
        : <App pca={msalInstance} />
    }
  </BrowserRouter>;
}

root.render(
  <>
    {
      process.env.REACT_APP_STRICT_MODE == 'true'
      ? <>
        <React.StrictMode>
          {
            renderBody()
          }
        </React.StrictMode>
      </>
      : renderBody()
    }
  </>
);
