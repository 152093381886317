import FiestaInfiniteSpinner from 'src/shared/components/infinite-spinner/FiestaInfiniteSpinner';

/**
 * Returns a div which contains a 'stay tuned' message.
 * @returns Div with 'stay tuned' message.
 */
export function StayTunedMessage() {
  return (
    <div style={{position: 'absolute', top: '50%', bottom: '50%', left: '50%', right: '50%'}}>
      <div style={{marginLeft: '-64px', marginTop: '-64px'}}>
        <FiestaInfiniteSpinner />
      </div>
    </div>
  );
}
