import { t } from 'i18next';
import { Knob } from 'primereact/knob';
import { useEffect, useState } from 'react';

export type FiestaCountdownProps = {
  startDate: Date;
  targetDate: Date;
  color?: string;
  label?: string;
  size: 'small' | 'medium' | 'large';
}

export default function FiestaCountdown(props: FiestaCountdownProps) {

  // Importing variables.
  const { startDate, targetDate, color, label, size } = props;

  // Variable declarations.
  const [ knobSize, setKnobSize ] = useState<number>(86);
  const [ days, setDays ] = useState<number>(0);
  const [ hours, setHours ] = useState<number>(0);
  const [ minutes, setMinutes ] = useState<number>(0);
  const [ seconds, setSeconds ] = useState<number>(0);
  
  // Differences and seconds.
  const currentSecs =
    new Date()
    .getTime() / 1000;
  
  const startDateSecs =
    startDate
    .getTime() / 1000;
  
  const targetDateSecs =
    targetDate
    .getTime() / 1000;
  
  const initialRunwaySecs =
    targetDateSecs -
    (currentSecs - startDateSecs) -
    startDateSecs;
  
  const totalRunwaySecs =
    targetDateSecs -
    startDateSecs;
  
  const totalDays =
    (totalRunwaySecs / (60 * 60 * 24));
  
  const [ timeRemainingSecs, setTimeRemaining ]
    = useState(initialRunwaySecs);

  /**
   * Initiate the timer.
   */
  useEffect(
    () => {
      const timerInterval =
        setInterval(
          () => {
            setTimeRemaining(
              (prevTime) => {
                if (prevTime === 0) {
                  clearInterval(timerInterval);
                  return 0;
                } else {
                  return prevTime - 1;
                }
              }
            );
          }, 
          1000
        );
      // Cleanup the interval when the component unmounts
      return () => clearInterval(timerInterval);
    },
    []
  ); // The empty dependency array ensures the effect runs only once on mount

  /**
   * Update the different timer values.
   */
  useEffect(
    () => {
      const days = Math.floor(timeRemainingSecs / (60 * 60 * 24));
      const hours = Math.floor((timeRemainingSecs % (60 * 60 * 24)) / (60 * 60));
      const minutes = Math.floor((timeRemainingSecs % (60 * 60)) / (60));
      const seconds = Math.floor(timeRemainingSecs % 60);

      setDays(days);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
    },
    [ timeRemainingSecs ]
  );

  /**
   * Set the knob size depending on the size selected.
   */
  useEffect(
    () => {
      switch (size) {
        case 'small':
          setKnobSize(64);
          break;
        case 'medium':
          setKnobSize(86);
          break;
        case 'large':
          setKnobSize(128);
          break;
      }
    },
    [ size ]
  );

  return <>
    <div className='flex flex-column gap-1'>
      {
        label
        &&
        <div className='text-center text-xl text-800 align-content-center p-2'>
          {label}
        </div>
      }
      <div className='flex flex-row gap-1'>
        <div className='flex flex-column gap-2 text-center'>
          <Knob
            min={0}
            max={totalDays}
            value={days}
            size={knobSize}
            color={color}
            inputMode='none'
          />
          <span className='text-sm text-400 uppercase'>
            {
              t('shared.components.countdown.labels.days')
            }
          </span>
        </div>
        <div className='flex flex-column gap-2 text-center'>
          <Knob
            min={0}
            max={24}
            value={hours}
            size={knobSize}
            color={color}
            inputMode='none'
          />
          <span className='text-sm text-400 uppercase'>
          {
            t('shared.components.countdown.labels.hours')
          }
          </span>
        </div>
        <div className='flex flex-column gap-2 text-center'>
          <Knob
            min={0}
            max={60}
            value={minutes}
            size={knobSize}
            color={color}
            inputMode='none'
          />
          <span className='text-sm text-400 uppercase'>
            {
              t('shared.components.countdown.labels.minutes')
            }
          </span>
        </div>
        <div className='flex flex-column gap-2 text-center'>
          <Knob
            min={0}
            max={60}
            value={seconds}
            size={knobSize}
            color={color}
            inputMode='none'
          />
          <span className='text-sm text-400 uppercase'>
            {
              t('shared.components.countdown.labels.seconds')
            }
          </span>
        </div>
      </div>
    </div>
  </>;
}