import { CGenre, GenresService, MeService, ProfileGenresGetResponse, ProfileGenresGetResponse_CProfileGenre } from "src/apis/api-client";
import { Card } from "primereact/card";
import { PropsWithChildren, useEffect, useState } from "react";
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { Chip } from "primereact/chip";
import { Button } from "primereact/button";
import { t } from 'i18next';

export type ProfileGenreProps = {
  profileId?: string;
  readonly?: boolean;
}

export function ProfileGenre(props: PropsWithChildren<ProfileGenreProps>) {

  /* Import variables from props. */
  const { profileId, readonly } = props;

  const [genres, setGenres] = useState<CGenre[]>([]);
  const [profileGenres, setProfileGenres] = useState<ProfileGenresGetResponse_CProfileGenre[]>([]);
  const [searchString, setSearchString] = useState<string>();
  const [selectedGenre, setSelecteGenre] = useState<CGenre>();

  /* Gets all genres for this profile */
  async function fetchProfileGenres() {
    if (profileId) {
      const response: ProfileGenresGetResponse
        = await MeService.getGenres(profileId);

      if (response.result) {
        setProfileGenres(response.result);
      }

      return response;
    }
  }

  /* Gets all genres that contains the search string */
  useEffect(
    () => {
      (
        async () => {
          if (searchString != undefined && searchString.length >= 2) {
            const response = await GenresService.get(searchString);
            response.result &&
              setGenres(response.result);
          }
        }
      )();
    },
    [searchString]
  );

  /* Post the selected genre to the database */
  useEffect(
    () => {
      (
        async () => {
          if (selectedGenre?.id) {
            await MeService.postGenre({ data: { profileId: profileId, genreId: selectedGenre.id } })
            setSelecteGenre(undefined);
            await fetchProfileGenres();
          }
        }
      )();
    },
    [selectedGenre]
  );

  /* Called with create of the component to get all genres for the profile */
  useEffect(
    () => {
      (
        async () => {
          await fetchProfileGenres();
        }
      )();
    },
    []
  );

  /* Returns the view to Post or Delete genres */
  return (
    <Card
      className="p-4 h-full shadow-none"
      title={!readonly ? t('profile.components.profileGenres.cardTitle') : t('profile.components.profileGenres.userCardTitle')}
      subTitle={!readonly ? t('profile.components.profileGenres.cardSubTitle') : t('profile.components.profileGenres.userCardSubTitle')}>
      <div className="flex flex-column gap-4 pb-2 pt-3">
        {
          !readonly &&
          <div className="flex">
            <AutoComplete
              className="flex w-full"
              inputStyle={{ width: '100%' }}
              placeholder="Type some genre"
              value={selectedGenre}
              suggestions={genres}
              field="title"
              completeMethod={(e: AutoCompleteCompleteEvent) => setSearchString(e.query)}
              onChange={(e: AutoCompleteChangeEvent) => setSelecteGenre(e.target.value)} />
          </div>
        }
      </div>
      <div className="flex gap-2 flex-wrap">
        {
          profileGenres.map((g) => (
            <Chip
              template={
                <div className="flex align-items-center gap-2">
                  <div className="flex align-items-center flex-wrap flex-column gap-1 lg:gap-3 lg:flex-row md:gap-3 lg:flex-row xl:flex-row xl:gap-3">
                    <span>{g.genre?.title}</span>
                  </div>
                  {
                    !readonly &&
                    <Button
                      className="text-xs p-button-text w-auto p-button-danger"
                      icon='pi pi-trash'
                      onClick={async () => {
                        g.id &&
                          await MeService.deleteGenre(g.id);
                        await fetchProfileGenres();
                      }}
                    />}
                </div>
              }
            >
            </Chip>
          ))
        }
      </div>
    </Card>
  );
}
