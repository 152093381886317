import type { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'; // Icons
import 'primereact/resources/primereact.min.css'; // Core css
// import 'primereact/resources/themes/lara-dark-amber/theme.css'; // Theme
import { QueryClient, QueryClientProvider } from 'react-query';
import './App.css';
import { CoreRouter } from './core';
import { AuthPageContainer } from './shared';

type AppProps = {
	pca: IPublicClientApplication;
};

const queryClient = new QueryClient();

function App({pca}: AppProps) {
	return (
		<MsalProvider instance={pca}>
			<QueryClientProvider client={queryClient}>
        <AuthPageContainer>
				  <CoreRouter />
        </AuthPageContainer>
			</QueryClientProvider>
		</MsalProvider>
	);
}

export default App;
