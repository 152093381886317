import FiestaInfiniteSpinner from 'src/shared/components/infinite-spinner/FiestaInfiniteSpinner';
import i18next, { t } from 'i18next';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Skeleton } from 'primereact/skeleton';
import { Steps } from 'primereact/steps';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BasketsPostResponse, CBasket, CEvent, EventsGetDetailResponse, EventsService, ProductsGetResponse, ProductsGetResponse_Product, ProgramsGetDetailResponse, ProgramsGetDetailResponse_CProgram, ProgramsService } from 'src/apis/api-client';
import { FiestaImageHeader, getTranslationKey } from 'src/shared';
import { BasketProduct } from '../../components';

export function BasketCreateView() {

  /* Using params, as we are expecting the program id. */
  const routeParams = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [program, setProgram] = useState<ProgramsGetDetailResponse_CProgram>();
  const [event, setEvent] = useState<CEvent>();
  const [products, setProducts] = useState<ProductsGetResponse_Product[]>();

  const [checkBoxState, setCheckboxState] = useState<boolean>(false);
  const [inputState, setInputState] = useState<boolean>(false);

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const [basket, setBasket] = useState<CBasket>();

  const [name, setName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [mail, setMail] = useState<string>('');
  const [language, setLanguage] = useState<string>('de');

  const [fullPrice, setFullPrice] = useState<number>(0);
  const [earnedPoints, setEarnedPoints] = useState<number>(0);

  const [basketProducts, setBasketProducts] = useState<Record<string, number>>({});
  const [isFilled, setIsFilled] = useState<boolean>(false);

  // Sets the basket if a input has changed. Also checks if the inputs are valid.
  useEffect(
    () => {
      setBasket({
        name: name,
        surname: surname,
        mailAddress: mail
      })

      if (name.trim() !== '' && surname.trim() !== '' && mail.trim() !== '' && mail.includes('@')) {
        setInputState(true);
      } else {
        setInputState(false);
      }

    }, [name, surname, mail]
  )

  //Calc the full price for this order.
  useEffect(
    () => {
      calcFullPrice();
    }, [basketProducts]
  )

  //Calc the points for this order.
  useEffect(
    () => {
      setEarnedPoints((fullPrice * 10) * 5)
    }, [fullPrice]
  )

  /* Fetch the data for the products, program and event. */
  useEffect(
    () => {
      (
        async () => {
          if (!routeParams.programId) return;

          setLoading(true);

          const poductResponse: ProductsGetResponse
            = await ProgramsService.getProducts(routeParams.programId);

          if (poductResponse.result) {
            setProducts(poductResponse.result);
          }

          const response: ProgramsGetDetailResponse
            = await ProgramsService.getDetail(routeParams.programId);

          if (
            response && response.data
          ) {
            setProgram(response.data);

            if (response.data.eventId) {
              const eventResponse: EventsGetDetailResponse
                = await EventsService.getDetail(response.data.eventId);

              if (
                eventResponse && eventResponse.data
              ) {
                setEvent(eventResponse.data);
              }
            }
          }

          setLoading(false);
        }
      )();
    },
    [routeParams.programId]
  );

  // Clear the basket and go back to the customer info step.
  function goBackToInfos() {
    setBasketProducts({});
    setActiveIndex(0);
  }

  /**
   * Concatenates a new key-value pair into the 'basketProducts' object, removing entries with a value of 0.
   * @param key The key to add or update in the 'basketProducts' object.
   * @param value The value to associate with the 'key' in the 'basketProducts' object.
   */
  function concatenateRecords(key: string, value: number) {
    const filteredRecord: Record<string, number> = {};
    if (key) {
      const newProduct = { ...basketProducts };
      newProduct[key] = value;

      const concate = { ...basketProducts, ...newProduct };

      for (const key in concate) {
        if (concate[key] !== 0) {
          filteredRecord[key] = concate[key];
        }
      }
      setBasketProducts(filteredRecord);
    }
  }

  /**
   * Calculates the total price of items in the basket based on the 'basketProducts' object and updates the 'fullPrice' state.
   */
  function calcFullPrice() {
    let totalPrice = 0;
    setIsFilled(false);
    for (const key in basketProducts) {
      setIsFilled(true);
      const product = products?.find((e) => e.id === key);

      if (product?.price) {
        const productPrice = product.price * basketProducts[key];
        totalPrice += productPrice;
      }
    }
    setFullPrice(totalPrice);
  }

  /**
   * Adds a basket to a program, updating the active index if successful.
   */
  async function addBasket() {
    if (basket && program?.id) {
      setLoading(true);
      const response: BasketsPostResponse =
        await ProgramsService.postBasket(program?.id, { ...basket, products: basketProducts, programId: program.id, language: i18next.language });

      if (response) {
        setActiveIndex(2);
      }

      setLoading(false);
    }
  }

  return (
    <div className='surface-card'>
      <FiestaImageHeader
        loading={loading}
        title={
          event
            && event.title
            && event.id
            ? t(getTranslationKey(event.id, 'event_title')) || event?.title
            : ''
        }
        subTitle={
          program
            && program.title
            && program.id
            ? t(getTranslationKey(program.id, 'program_title')) || program?.title
            : ''
        }
        image={
          program
            && program.image
            ? program.image
            : ''
        }
        logo={
          event
            && event.logo
            ? event.logo
            : ''
        }
      />
      <div className='p-4'>
        <div className='pb-4'>
          <Steps
            activeIndex={activeIndex}
            onSelect={(e) => { setActiveIndex(e.index); }}
            className='flex flex-row f-width-tablist'
            model={
              [
                { label: 'Informations', className: 'flex flex-1' },
                { label: 'Create Basket', className: 'flex flex-1' },
                { label: 'Confirmation', className: 'flex flex-1' }
              ]
            }
          />
        </div>
        {
          activeIndex === 0
          &&
          <div className='lg:col-offset-3 col-12 lg:col-6'>
            {
              program
              && <p
                className='text-center'
                dangerouslySetInnerHTML={{ __html: t('domains.basket.views.basketCreateView.introduction') }}
              >
              </p>
            }
            {
              program
                && program.id
                ?
                <div className='w-full flex flex-column gap-5 pb-4'>
                  <span className='p-float-label'>
                    <InputText
                      keyfilter='alpha'
                      className='w-full'
                      id='name'
                      placeholder='z.B. Max'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label htmlFor='name'>{t('domains.basket.views.basketCreateView.floatLabelName')}</label>
                  </span>
                  <span className='p-float-label'>
                    <InputText
                      keyfilter='alpha'
                      className='w-full'
                      id='surname'
                      placeholder='z.B. Mustermann'
                      value={surname}
                      onChange={(e) => setSurname(e.target.value)}
                    />
                    <label htmlFor='surname'>{t('domains.basket.views.basketCreateView.floatLabelSurname')}</label>
                  </span>
                  <span className='p-float-label'>
                    <InputText
                      keyfilter='email'
                      className='w-full'
                      id='mail'
                      placeholder='z.B. max.mustermann@web.de'
                      value={mail}
                      onChange={(e) => setMail(e.target.value)}
                    />
                    <label htmlFor='mail'>E-Mail</label>
                  </span>
                  <span className='p-float-label'>
                    <Dropdown
                      inputId='dd-city'
                      value={language}
                      onChange={(e) => setLanguage(e.value)}
                      options={
                        [
                          { value: 'de', name: 'common.languages.de.label' },
                          { value: 'en', name: 'common.languages.en.label' },
                        ]
                      }
                      itemTemplate={
                        (option) => (
                          <div className='flex flex-row gap-2'>
                            <Image src={`/assets/images/flag_${option.value}.png`} width='20px' />
                            <span>{t(option.name)}</span>
                          </div>
                        )
                      }
                      valueTemplate={
                        (option) => (
                          <div className='flex flex-row gap-2'>
                            <Image src={`/assets/images/flag_${option.value}.png`} width='20px' />
                            <span>{t(option.name)}</span>
                          </div>
                        )
                      }
                      optionLabel='name'
                      className='w-full'
                    />
                    <label htmlFor='dd-city'>{t('domains.basket.views.basketCreateView.floatLabelLanguage')}</label>
                  </span>
                </div>
                : <div className='gap-2 flex-column flex'>
                  <Skeleton height='18px' />
                  <Skeleton height='18px' />
                  <Skeleton height='18px' />
                </div>
            }
            <div
              className='flex align-items-center gap-2 pt-3'>
              <Checkbox
                checked={checkBoxState}
                onChange={() => setCheckboxState(!checkBoxState)}
              />
              <span>
                {t('domains.basket.views.basketCreateView.acceptText')}
              </span>
            </div>
            <div className='flex flex-row gap-2 p-4'>
              {
                !loading
                  ?
                  <div className='flex-1'>
                    <Button
                      className='w-full'
                      label={
                        t('domains.programs.views.join.joinView.buttons.accept')
                      }
                      onClick={() => setActiveIndex(1)}
                      disabled={(!inputState) || (!checkBoxState)}
                    />
                  </div>
                  : <Skeleton
                    height='48px'
                    className='flex-1'
                  />
              }
              {
                !loading
                  ? <Button
                    className='flex-1 p-button-outlined p-button-secondary'
                    label={
                      t('domains.basket.views.basketCreateView.conrirmationProgrammButton')
                    }
                    onClick={() => navigate('/programs/' + program?.id + '/dashboard')}
                  />
                  : <Skeleton
                    height='48px'
                    className='flex-1'
                  />
              }
            </div>
          </div>
        }
        {
          activeIndex === 1
          &&
          <div className='lg:col-offset-3 col-12 lg:col-6'>
            {
              program
              && <p
                className='text-center'
                dangerouslySetInnerHTML={{ __html: t('domains.basket.views.basketCreateView.basketIntroduction') }}
              >
              </p>
            }
            {
              !loading ?
                <div>
                  {
                    products?.map((e) => (
                      <div>
                        {
                          e.isAvailable &&
                          <BasketProduct
                            product={e}
                            onChange={(key, value) => concatenateRecords(key, value)}
                          />
                        }
                      </div>
                    ))
                  }
                  <div className='flex justify-content-between text-900 pt-2 font-medium font-medium border-top-1 border-300 w-full pb-5'>
                    <strong className='pl-0'>{t('domains.basket.views.basketCreateView.totalPriceLabel')}</strong>
                    <strong>{fullPrice.toFixed(2)} €</strong>
                  </div>
                  <Message
                    severity='info'
                    text={t('domains.basket.views.basketCreateView.basketAcceptText', { value: earnedPoints })}
                  />
                  <div className='flex flex-row gap-2 pt-4'>
                    {
                      !loading
                        ?
                        <div className='flex-1'>
                          <Button
                            className='w-full'
                            label={
                              t('domains.basket.views.basketCreateView.sendBasketButtonText')
                            }
                            disabled={!isFilled}
                            onClick={() => addBasket()}
                          />
                        </div>
                        : <Skeleton
                          height='48px'
                          className='flex-1'
                        />
                    }
                    {
                      !loading
                        ? <Button
                          className='flex-1 p-button-outlined p-button-secondary'
                          label={
                            t('domains.basket.views.basketCreateView.returnToInfosButtonText')
                          }
                          onClick={() => goBackToInfos()}
                        />
                        : <Skeleton
                          height='48px'
                          className='flex-1'
                        />
                    }
                  </div>
                </div> : <div className='flex'><FiestaInfiniteSpinner /></div>
            }
          </div>
        }
        {
          activeIndex === 2
          &&
          <div className='lg:col-offset-3 col-12 lg:col-6'>
            {
              program
              &&
              <div className='text-center pt-3'>
                <i className='pi pi-verified text-green-400 pb-2' style={{ fontSize: '4em' }} />
                <p
                  className='text-center'
                  dangerouslySetInnerHTML={{ __html: t('domains.basket.views.basketCreateView.confirmationText') }}
                >
                </p>
              </div>
            }
            <div className='flex flex-row gap-2 p-4'>
              {
                !loading
                  ?
                  <div className='flex-1'>
                    <Button
                      className='w-full'
                      label={
                        t('domains.basket.views.basketCreateView.conrirmationProgrammButton')
                      }
                      onClick={() => navigate('/programs/' + program?.id + '/dashboard')}
                    />
                  </div>
                  : <Skeleton
                    height='48px'
                    className='flex-1'
                  />
              }
            </div>
          </div>
        }
      </div>
    </div>
  );
}
