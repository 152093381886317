import { BasketProductsGetResponse, BasketProductsGetResponse_Product, BasketService, BasketsGetResponse, CBasket, CBasketStatus } from "src/apis/api-client";
import { formatDateSpecific } from "src/shared";
import { Skeleton } from "primereact/skeleton";
import { StyleClass } from "primereact/styleclass";
import { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import i18next from 'i18next';
import { Tag } from "primereact/tag";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export type BasketListCardProps = {
  basket: CBasket;
}

export function BasketListCard(props: BasketListCardProps) {

  /* Import variables from props. */
  const { basket } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [basketId, setBasketId] = useState<string>();
  const [basketProducts, setBasketProducts] = useState<BasketProductsGetResponse_Product[]>();
  const [earnedPoints, setEarnedPoints] = useState<number>(0);
  const expandRef = useRef(null);

  /**
   * Update the basket list, as soon as the basket has been set.
   */
  useEffect(
    () => {
      (
        async () => {
          setLoading(true);
          setBasketId(basket.id);
          const transaction = await fetchBasketProducts();
          if (transaction?.result)
            setBasketProducts(transaction.result);
          setLoading(false);
        }
      )();
    },
    [basket]
  );

  useEffect(
    () => {
      calcFullPrice();
    }, [basketProducts]
  )

  /**
 * Calculates the total price of items in the basket based on the 'basketProducts' object and updates the 'fullPrice' state.
 */
  function calcFullPrice() {
    let totalPrice = 0;

    basketProducts?.map((e) => (
      e.amount && e.price ?
        totalPrice += e.amount * e.price
        : totalPrice += 0
    ))

    setEarnedPoints((totalPrice * 10) * 5);
  }


  /**
  * Fetches the basket products by id.
  * @returns BasketProductsGetResponse.
  */
  async function fetchBasketProducts() {
    if (basket.id) {
      const response: BasketProductsGetResponse =
        await BasketService.getProducts(basket.id);

      if (response) {
        return response;
      }
    }
  }

  /**
  * Renders a specific CBasketStatus as a Tag component.
  * @param status The CBasketStatus to render.
  * @returns A Tag component representing the CTransaction status.
  */
  function renderStatusTag(status: CBasketStatus) {
    switch (status) {
      case CBasketStatus.SENT:
        return <Tag
          severity="warning"
          value={t('domains.programs.views.dashboard.dashboardView.components.basket-list.components.basket-list-card.sentTagValue')}
        />;
      case CBasketStatus.SOLD:
        return <Tag
          severity="success"
          value={t('domains.programs.views.dashboard.dashboardView.components.basket-list.components.basket-list-card.soldTagValue')}
        />;
      case CBasketStatus.CANCELLED:
        return <Tag
          severity="danger"
          value={t('domains.programs.views.dashboard.dashboardView.components.basket-list.components.basket-list-card.cancelledTagValue')}
        />;
    }
  }

  /**
   * Renders a specific text for the CBasketStatus.
   * @param status The CBasketStatus.
   * @returns The correct text as string.
   */
  function renderEarnedPoints(status: CBasketStatus) {

    switch (status) {
      case CBasketStatus.SENT:
        return t('domains.programs.views.dashboard.dashboardView.components.basket-list.components.basket-list-card.basketSentEarnedPoints',
          {
            value: t('common.formatting.signlessPoints',
              {
                value: earnedPoints
              })
          });
      case CBasketStatus.SOLD:
        return t('domains.programs.views.dashboard.dashboardView.components.basket-list.components.basket-list-card.basketSoldEarnedPoints',
          {
            value: t('common.formatting.signlessPoints',
              {
                value: earnedPoints
              })
          });
      case CBasketStatus.CANCELLED:
        return t('domains.programs.views.dashboard.dashboardView.components.basket-list.components.basket-list-card.basketCancelledEarnedPoints',
          {
            value: t('common.formatting.signlessPoints',
              {
                value: earnedPoints
              })
          });
    }
  }

  /**
   * Renders a specific basket into a card element that can be shown.
   * @param value CBasket to render.
   * @returns div.
   */
  function renderBasket(value: CBasket) {
    return <div
      id={value.id}
      className={
        'p-4 flex flex-column flex-wrap border-round shadow-none surface-card hover:text-900 hover:surface-100'
      }
      ref={expandRef}
    >
      <StyleClass
        nodeRef={expandRef}
        selector={`#details-slide-${basketId}`}
        enterClassName="hidden"
        leaveToClassName="hidden"
      >
        {/* Main Content. */}
        <div
          className='flex flex-row flex-1'
          id={value.id}
        >
          <div className='flex flex-column gap-0 flex-2 pr-4'>
            <span className='text-xl font-bold'>
              {
                value != null &&
                `${t('domains.programs.views.dashboard.dashboardView.components.basket-list.components.basket-list-card.basketHeader')} ${value.name} ${value.surname}`
              }
            </span>
            {
              value != null
              && value != null
              && value.sentOn != null
              &&
              <span>
                {
                  value.sentOn != null &&
                  t('domains.programs.views.dashboard.dashboardView.components.basket-list.components.basket-list-card.sentOn')
                  + formatDateSpecific(value.sentOn, i18next.language)
                }
              </span>
            }
          </div>
          <div className='flex flex-1 justify-content-end align-items-center'>
            {
              value.status &&
              renderStatusTag(value.status)
            }
          </div>
        </div>

        {/* Additional Content / Details. */}
        <div
          className='flex flex-row flex-1'
        >
          <div
            className='hidden'
            id={`details-slide-${basketId}`}
          >
            {
              <div>
                <div className="flex-column pt-4 pb-2">
                  <div className="pb-2">
                    <strong>{t('domains.programs.views.dashboard.dashboardView.components.basket-list.components.basket-list-card.basketTickets')}</strong>
                  </div>
                </div>
                <div className="flex-1 flex-column border-top-1 border-400">
                  {
                    basketProducts?.map((p) => (
                      <div className="flex gap-5 pt-2 pb-2">
                        <strong>{p.amount}x </strong>
                        <span className="">{p.name}</span>
                      </div>
                    ))
                  }
                </div>
                <div className="gap-2 pt-4 flex border-top-1 border-400">
                  <i className='pi pi-info-circle align-self-center' />
                  {
                    value.status &&
                    <span dangerouslySetInnerHTML={{ __html: renderEarnedPoints(value.status) }}>
                    </span>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </StyleClass>
    </div>;
  }

  return (
    <div
      className='flex flex-column'
    >
      {
        !loading
        && basket
        &&
        renderBasket(basket)
      }
      {
        loading
        &&
        <Skeleton height='75px' />
      }
    </div>
  );
}
