/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RewardsGetResponse } from '../models/RewardsGetResponse';
import type { ShopsGetDetailResponse } from '../models/ShopsGetDetailResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ShopsService {

    /**
     * @param shopId
     * @returns ShopsGetDetailResponse OK
     * @throws ApiError
     */
    public static getDetail(
        shopId: string,
    ): CancelablePromise<ShopsGetDetailResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Shops/{shopId}',
            path: {
                'shopId': shopId,
            },
        });
    }

    /**
     * @param shopId
     * @param skip
     * @param take
     * @returns RewardsGetResponse OK
     * @throws ApiError
     */
    public static getRewards(
        shopId: string,
        skip?: number,
        take?: number,
    ): CancelablePromise<RewardsGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Shops/{shopId}/Rewards',
            path: {
                'shopId': shopId,
            },
            query: {
                'skip': skip,
                'take': take,
            },
        });
    }

}
