/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProfileGenresDeleteResponse } from '../models/ProfileGenresDeleteResponse';
import type { ProfileGenresGetResponse } from '../models/ProfileGenresGetResponse';
import type { ProfileGenresPostRequest } from '../models/ProfileGenresPostRequest';
import type { ProfileGenresPostResponse } from '../models/ProfileGenresPostResponse';
import type { ProfilesDeleteResponse } from '../models/ProfilesDeleteResponse';
import type { ProfilesGetDetailResponse } from '../models/ProfilesGetDetailResponse';
import type { ProfileSocialMediaDeleteResponse } from '../models/ProfileSocialMediaDeleteResponse';
import type { ProfileSocialMediaGetResponse } from '../models/ProfileSocialMediaGetResponse';
import type { ProfileSocialMediaPostRequest } from '../models/ProfileSocialMediaPostRequest';
import type { ProfileSocialMediaPostResponse } from '../models/ProfileSocialMediaPostResponse';
import type { ProfileSocialMediaPutRequest } from '../models/ProfileSocialMediaPutRequest';
import type { ProfileSocialMediaPutResponse } from '../models/ProfileSocialMediaPutResponse';
import type { ProfilesPictureDeleteResponse } from '../models/ProfilesPictureDeleteResponse';
import type { ProfilesPicturePutResponse } from '../models/ProfilesPicturePutResponse';
import type { ProfilesPutRequest } from '../models/ProfilesPutRequest';
import type { ProfilesPutResponse } from '../models/ProfilesPutResponse';
import type { PurchasesGetResponse } from '../models/PurchasesGetResponse';
import type { UsersMeResponse } from '../models/UsersMeResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MeService {

    /**
     * @returns UsersMeResponse OK
     * @throws ApiError
     */
    public static getMe(): CancelablePromise<UsersMeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Me',
        });
    }

    /**
     * @param profileId
     * @param requestBody
     * @returns ProfilesPutResponse OK
     * @throws ApiError
     */
    public static put(
        profileId?: string,
        requestBody?: ProfilesPutRequest,
    ): CancelablePromise<ProfilesPutResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Me/Profile',
            query: {
                'profileId': profileId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param profileId
     * @returns ProfilesGetDetailResponse OK
     * @throws ApiError
     */
    public static getDetail(
        profileId?: string,
    ): CancelablePromise<ProfilesGetDetailResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Me/Profile',
            query: {
                'profileId': profileId,
            },
        });
    }

    /**
     * @returns ProfilesDeleteResponse OK
     * @throws ApiError
     */
    public static delete(): CancelablePromise<ProfilesDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Me/Profile',
        });
    }

    /**
     * @param profileId
     * @returns ProfileGenresGetResponse OK
     * @throws ApiError
     */
    public static getGenres(
        profileId: string,
    ): CancelablePromise<ProfileGenresGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Me/Profile/{profileId}/ProfileGenres',
            path: {
                'profileId': profileId,
            },
        });
    }

    /**
     * @param profileGenreId
     * @returns ProfileGenresDeleteResponse OK
     * @throws ApiError
     */
    public static deleteGenre(
        profileGenreId: string,
    ): CancelablePromise<ProfileGenresDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Me/Profile/{profileGenreId}/ProfileGenres',
            path: {
                'profileGenreId': profileGenreId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns ProfileGenresPostResponse OK
     * @throws ApiError
     */
    public static postGenre(
        requestBody?: ProfileGenresPostRequest,
    ): CancelablePromise<ProfileGenresPostResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Me/Profile/ProfileGenres',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param profileId
     * @returns ProfileSocialMediaGetResponse OK
     * @throws ApiError
     */
    public static getSocialMedia(
        profileId: string,
    ): CancelablePromise<ProfileSocialMediaGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Me/Profile/{profileId}/ProfileSocialMedia',
            path: {
                'profileId': profileId,
            },
        });
    }

    /**
     * @param profileSocialMediaId
     * @returns ProfileSocialMediaDeleteResponse OK
     * @throws ApiError
     */
    public static deleteSocialMedia(
        profileSocialMediaId: string,
    ): CancelablePromise<ProfileSocialMediaDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Me/Profile/{profileSocialMediaId}/ProfileSocialMedia',
            path: {
                'profileSocialMediaId': profileSocialMediaId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns ProfileSocialMediaPostResponse OK
     * @throws ApiError
     */
    public static postSocialMedia(
        requestBody?: ProfileSocialMediaPostRequest,
    ): CancelablePromise<ProfileSocialMediaPostResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Me/Profile/ProfileSocialMedia',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param profileId
     * @param requestBody
     * @returns ProfileSocialMediaPutResponse OK
     * @throws ApiError
     */
    public static putSocialMedia(
        profileId?: string,
        requestBody?: ProfileSocialMediaPutRequest,
    ): CancelablePromise<ProfileSocialMediaPutResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Me/Profile/ProfileSocialMedia',
            query: {
                'profileId': profileId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param formData
     * @returns ProfilesPicturePutResponse OK
     * @throws ApiError
     */
    public static putPicture(
        formData?: {
            formFile?: Blob;
        },
    ): CancelablePromise<ProfilesPicturePutResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Me/Profile/Picture',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns ProfilesPictureDeleteResponse OK
     * @throws ApiError
     */
    public static deletePicture(): CancelablePromise<ProfilesPictureDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Me/Profile/Picture',
        });
    }

    /**
     * @param skip
     * @param take
     * @returns PurchasesGetResponse OK
     * @throws ApiError
     */
    public static getRewards(
        skip?: number,
        take: number = 25,
    ): CancelablePromise<PurchasesGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Me/Purchase',
            query: {
                'skip': skip,
                'take': take,
            },
        });
    }

}
