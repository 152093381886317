import { EventType, type AuthenticationResult, type Configuration, type EventMessage, type PopupRequest, type PublicClientApplication, type SilentRequest } from '@azure/msal-browser';
import axios, { type AxiosRequestConfig } from 'axios';
import { ConversionUtils } from 'turbocommons-ts';

/**
 * Gets a state portion, the auth request will send back again.
 * @returns The window.location.pathname.
 */
export function encodeState(): string | undefined {
  return ConversionUtils.stringToBase64(window.location.pathname);
}

/**
 * Decodes the state, which should be in the Base64 format.
 * @param stateValue Base64 encoded string.
 * @returns Decoded string value.
 */
export function decodeState(stateValue: string | undefined): string | undefined {
  if (stateValue) {
    return ConversionUtils.base64ToString(stateValue);
  }
  return undefined;
}

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
	auth: {
		clientId: process.env.REACT_APP_MSAL_API_CLIENT_ID ?? "",
		authority: process.env.REACT_APP_MSAL_API_AUTHORITY ?? "",
		redirectUri: '/',
		postLogoutRedirectUri: '/',
    	knownAuthorities: [ "login.microsoftonline.com", "fiestaambassadors.b2clogin.com", "login.fiesta.club" ]
	},
	cache: {
		cacheLocation: 'localStorage'
	}
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
	scopes: process.env.REACT_APP_MSAL_API_SCOPES?.split(",") ?? [],
  state: encodeState()
};

/**
 * Set's up the given msalInstance with a event, which handles custom payload.
 * @param msalInstance The MSAL instance to apply intercept to.
 */
export function setupPayloadIntercept(msalInstance: PublicClientApplication) {
	msalInstance
		.addEventCallback(
    	(event: EventMessage) => {
				if (
					event.eventType === EventType.LOGIN_SUCCESS
					&& event.payload !== null
				) {
					const payload = event.payload as AuthenticationResult;
						// The payload contains the path that has been called.
						// This will just redirect-utils to the same, so the authentication doesn't get the user lost.
						if (payload.state) {
							const decodedPath = decodeState(payload.state);
							if (decodedPath) {
								window.location.href = decodedPath;
							}
						}
				} else if (
					event.eventType === EventType.LOGOUT_SUCCESS
					|| event.eventType === EventType.ACCOUNT_REMOVED
				) {
					window.location.href = 'https://fiesta.club';
				}
	  	}
  	);
}

/**
 * Set's up a interceptor for AXIOS, which handles the addition of the Bearer Token.
 * This way, the application can authorize against the backend services.
 * @param instance The PublicClientApplication to use as token source.
 */
export function setupApiBearerAuthentication(instance: PublicClientApplication) {
	// Request Interceptors.
	axios.interceptors.request.use(
		async (config: AxiosRequestConfig) => {
			const accounts = instance.getAllAccounts();
			if (accounts.length > 0) {

				const silentRequest: SilentRequest = {
					scopes: loginRequest.scopes,
					account: accounts[0],
				};

				try {
					const accessTokenResponse =
						await instance
							.acquireTokenSilent(silentRequest);

					if (config.headers) {
						config.headers['Content-Type'] = 'application/json';
					}

					if (accessTokenResponse) {
						const accessToken = accessTokenResponse.accessToken;
						if (config.headers && accessToken) {
							config.headers.Authorization = `Bearer ${accessToken}`;
						}
					}
				} catch {
					await instance.acquireTokenRedirect(silentRequest);
				}
			}

			return config;
		},
		error => {
			void Promise.reject(error);
		},
	);

	// Response Interceptors.
	axios.interceptors.response.use(
		response => response,
		async error => {
			if (error.response.status === 401) {
				// Redirect the user to login again.
				await instance.acquireTokenRedirect(loginRequest);
			}
			return Promise.reject(error);
		}
	);
}