/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ShopEntryStatus {
    MINIMUM_NOT_REACHED = 'MinimumNotReached',
    SHOP_NOT_PUBLISHED = 'ShopNotPublished',
    SHOP_CLOSED = 'ShopClosed',
    SHOP_OPENED = 'ShopOpened',
}
