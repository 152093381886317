import FiestaInfiniteSpinner from 'src/shared/components/infinite-spinner/FiestaInfiniteSpinner';
import { t } from 'i18next';
import { Badge } from 'primereact/badge';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import { useEffect, useState } from 'react';
import { ProgramsService, ProgramStatisticsGetRankingResponse, ProgramStatisticsGetRankingResponse_CRanking } from 'src/apis/api-client';
import { ProfileShortView } from 'src/domains/profile';
import { FiestaInfoMessage } from 'src/shared';

export type RankingListProps = {
  programId: string;
}

export function RankingList(props: RankingListProps) {

  /* Import variables from props. */
  const { programId } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [ranking, setRanking] = useState<ProgramStatisticsGetRankingResponse_CRanking[]>();
  const [rankingLength, setRankingLength] = useState<number>();

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  /* Reload the ranking on state change. */
  useEffect(
    () => {
      (
        async () => {
          setLoading(true);

          const ranking = await fetchRanking();

          if (ranking?.result)
            setRanking(ranking.result);

          if (ranking?.totalCount != null)
            setRankingLength(ranking.totalCount);

          setLoading(false);
        }
      )();
    },
    [programId, first, rows]
  );

  /* Fetches the ranking using the first and rows values. */
  async function fetchRanking() {
    const response: ProgramStatisticsGetRankingResponse =
      await ProgramsService.getRanking(programId, first, rows);

    if (response) {
      return response;
    }
  }

  /* Sets the new first and rows values. */
  const onBasicPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
  }

  /* Renders a single ranking row on the list. */
  function renderRanking(value: ProgramStatisticsGetRankingResponse_CRanking) {
    return <div
      className={
        `flex flex- p-4 border-round p-overlay-badge shadow-none ${value.itsYou == true ? 'surface-200' : 'surface-card'}`
      }
    >
      <div className=''>
        <Badge
          className='surface-600'
          style={{ top: '9%', right: '100%' }}
          value={value.rank}
        />
      </div>
      <div className='flex w-full flex-wrap'>
        <div className='flex flex-1 w-full'>
          {
            value.profileId ?
              <ProfileShortView
                showSocials={true}
                profileId={value.profileId}
                itsYou={value.itsYou}
                textColor={value.itsYou ? 'black' : null}
              />
              :
              value.name !== '' ?
                <ProfileShortView
                  showSocials={true}
                  name={value.name}
                  itsYou={value.itsYou}
                  textColor={value.itsYou ? 'black' : null}
                />
                :
                <ProfileShortView
                  showSocials={false}
                  name={t('profile.components.profileShortView.lazyUserName')}
                  location={t('profile.components.profileShortView.lazyUserLocation')}
                />
          }
        </div>
        <div className='flex flex-1 pl-2 justify-content-end align-items-center'>
          {
            t('common.formatting.signlessPoints', { value: value.earnedPoints })
          }
        </div>
      </div>
    </div>;
  }

  /**
   * Renders the message, if the shop is not available at the moment.
   * @returns Message that has been rendered.
   */
  function renderMessage() {
    return <FiestaInfoMessage
      icon='history'
      title={t('domains.programs.views.dashboard.dashboardView.components.ranking-list.emptyMessage.title')}
      textContent={t('domains.programs.views.dashboard.dashboardView.components.ranking-list.emptyMessage.textContent')}
    />
  }

  return (
    <div
      className='flex flex-column mt-3'
    >
      {
        !loading
        && ranking
        && ranking.length > 0
        &&
        <>
          <Paginator
            first={first}
            rows={rows}
            totalRecords={rankingLength}
            rowsPerPageOptions={[10, 20, 30]}
            onPageChange={onBasicPageChange}
          />
          <div className='flex flex-column gap-2 pt-2'>
            {
              ranking
              &&
              ranking.map((rank) => renderRanking(rank))
            }
          </div>
        </>
      }
      {
        loading
        &&
        <div className='flex flex-column align-items-center mt-2'>
          <FiestaInfiniteSpinner />
        </div>
      }
      {
        !loading
        && (
          !ranking
          || ranking?.length == 0
        )
        &&
        renderMessage()
      }
    </div>
  );
}

export default RankingList;
