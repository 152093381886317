import FiestaInfiniteSpinner from 'src/shared/components/infinite-spinner/FiestaInfiniteSpinner';
import { t } from 'i18next';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import { useEffect, useState } from 'react';
import { MeService, PurchasesGetResponse, PurchasesGetResponse_CPurchase } from 'src/apis/api-client';
import { FiestaInfoMessage } from 'src/shared';
import { PurchasedRewardsListCard } from './components';

export function ProfilePurchasedRewardsList() {

  const [rewards, setRewards] = useState<PurchasesGetResponse_CPurchase[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const [purchasedRewardsLength, setPurchasedRewardsLength] = useState<number>();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);


  /**
 * Loads all needed objects initially.
 */
  useEffect(
    () => {
      (
        async () => {
          setLoading(true);

          const purchasedRewards = await fetchPurchasedRewards();

          if (purchasedRewards?.result) {
            setRewards(purchasedRewards.result);
            setPurchasedRewardsLength(purchasedRewards.totalCount);
          }

          setLoading(false);
        }
      )();
    },
    [first, rows]
  );

  /**
 * Fetches the purchased rewards for the user.
 * @returns The result of the fetch operation.
 */
  async function fetchPurchasedRewards() {
    const response: PurchasesGetResponse =
      await MeService.getRewards(first, rows);

    if (response) {
      return response;
    }
  }

  /* Sets the new first and rows values. */
  const onBasicPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
  }

  return (
    <div
      className='flex flex-column mt-3'
    >
      {
        rewards
        && rewards.length > 0
        &&
        <>
          <Paginator
            first={first}
            rows={rows}
            totalRecords={purchasedRewardsLength}
            rowsPerPageOptions={[10, 20, 30]}
            onPageChange={onBasicPageChange}
          />
          {
            rewards.map(
              (reward) => {
                if (reward.id) {
                  return <PurchasedRewardsListCard purchasedReward={reward} />
                }
              }
            )
          }
        </>
      }
      {
        !loading
        && rewards
        && rewards.length === 0
        &&
        <FiestaInfoMessage
          icon='lock'
          title={t('profile.components.profilePurchasedRewards.noPurchasesTitle')}
          textContent={t('profile.components.profilePurchasedRewards.noPurchasesText')}
        />
      }
      {
        loading
        &&
        <div className='flex flex-column align-items-center'>
          <FiestaInfiniteSpinner />
        </div>
      }
    </div>
  );
}
