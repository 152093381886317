/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasketsGetResponse } from '../models/BasketsGetResponse';
import type { BasketsPostRequest } from '../models/BasketsPostRequest';
import type { BasketsPostResponse } from '../models/BasketsPostResponse';
import type { ManagersGetResponse } from '../models/ManagersGetResponse';
import type { ProductsGetResponse } from '../models/ProductsGetResponse';
import type { ProgramLinksGetResponse } from '../models/ProgramLinksGetResponse';
import type { ProgramRulesGetResponse } from '../models/ProgramRulesGetResponse';
import type { ProgramsGetDetailResponse } from '../models/ProgramsGetDetailResponse';
import type { ProgramsGetResponse } from '../models/ProgramsGetResponse';
import type { ProgramStatisticsGetParticipantsResponse } from '../models/ProgramStatisticsGetParticipantsResponse';
import type { ProgramStatisticsGetPointsResponse } from '../models/ProgramStatisticsGetPointsResponse';
import type { ProgramStatisticsGetRankingResponse } from '../models/ProgramStatisticsGetRankingResponse';
import type { ProgramStatisticsGetRankResponse } from '../models/ProgramStatisticsGetRankResponse';
import type { ReferralIdentifiersGetResponse } from '../models/ReferralIdentifiersGetResponse';
import type { TransactionsGetResponse } from '../models/TransactionsGetResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProgramsService {

    /**
     * @param skip
     * @param take
     * @param onlyUnregistered
     * @param onlyRegistered
     * @returns ProgramsGetResponse OK
     * @throws ApiError
     */
    public static get(
        skip?: number,
        take: number = 25,
        onlyUnregistered: boolean = false,
        onlyRegistered: boolean = false,
    ): CancelablePromise<ProgramsGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programs',
            query: {
                'skip': skip,
                'take': take,
                'onlyUnregistered': onlyUnregistered,
                'onlyRegistered': onlyRegistered,
            },
        });
    }

    /**
     * @param programId
     * @returns ProgramsGetDetailResponse OK
     * @throws ApiError
     */
    public static getDetail(
        programId: string,
    ): CancelablePromise<ProgramsGetDetailResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programs/{programId}',
            path: {
                'programId': programId,
            },
        });
    }

    /**
     * @param programId
     * @param skip
     * @param take
     * @returns ProgramLinksGetResponse OK
     * @throws ApiError
     */
    public static getLinks(
        programId: string,
        skip?: number,
        take: number = 25,
    ): CancelablePromise<ProgramLinksGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programs/{programId}/Links',
            path: {
                'programId': programId,
            },
            query: {
                'skip': skip,
                'take': take,
            },
        });
    }

    /**
     * @param programId
     * @returns ReferralIdentifiersGetResponse OK
     * @throws ApiError
     */
    public static getReferralIdentifiers(
        programId: string,
    ): CancelablePromise<ReferralIdentifiersGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programs/{programId}/ReferralIdentifiers',
            path: {
                'programId': programId,
            },
        });
    }

    /**
     * @param programId
     * @returns ProgramStatisticsGetPointsResponse OK
     * @throws ApiError
     */
    public static getPoints(
        programId: string,
    ): CancelablePromise<ProgramStatisticsGetPointsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programs/{programId}/EarnedPoints',
            path: {
                'programId': programId,
            },
        });
    }

    /**
     * @param programId
     * @returns ProgramStatisticsGetParticipantsResponse OK
     * @throws ApiError
     */
    public static getParticipants(
        programId: string,
    ): CancelablePromise<ProgramStatisticsGetParticipantsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programs/{programId}/Participants',
            path: {
                'programId': programId,
            },
        });
    }

    /**
     * @param programId
     * @returns ProgramStatisticsGetRankResponse OK
     * @throws ApiError
     */
    public static getRank(
        programId: string,
    ): CancelablePromise<ProgramStatisticsGetRankResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programs/{programId}/Rank',
            path: {
                'programId': programId,
            },
        });
    }

    /**
     * @param programId
     * @param skip
     * @param take
     * @param focus
     * @returns ProgramStatisticsGetRankingResponse OK
     * @throws ApiError
     */
    public static getRanking(
        programId: string,
        skip?: number,
        take: number = 25,
        focus: boolean = false,
    ): CancelablePromise<ProgramStatisticsGetRankingResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programs/{programId}/Ranking',
            path: {
                'programId': programId,
            },
            query: {
                'skip': skip,
                'take': take,
                'focus': focus,
            },
        });
    }

    /**
     * @param programId
     * @param skip
     * @param take
     * @returns TransactionsGetResponse OK
     * @throws ApiError
     */
    public static getTransactions(
        programId: string,
        skip?: number,
        take: number = 25,
    ): CancelablePromise<TransactionsGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programs/{programId}/Transactions',
            path: {
                'programId': programId,
            },
            query: {
                'skip': skip,
                'take': take,
            },
        });
    }

    /**
     * @param programId
     * @param skip
     * @param take
     * @returns ProductsGetResponse OK
     * @throws ApiError
     */
    public static getProducts(
        programId: string,
        skip?: number,
        take: number = 25,
    ): CancelablePromise<ProductsGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programs/{programId}/Products',
            path: {
                'programId': programId,
            },
            query: {
                'skip': skip,
                'take': take,
            },
        });
    }

    /**
     * @param programId
     * @param requestBody
     * @returns BasketsPostResponse OK
     * @throws ApiError
     */
    public static postBasket(
        programId: string,
        requestBody?: BasketsPostRequest,
    ): CancelablePromise<BasketsPostResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Programs/{programId}/Baskets',
            path: {
                'programId': programId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param programId
     * @param skip
     * @param take
     * @returns BasketsGetResponse OK
     * @throws ApiError
     */
    public static getBaskets(
        programId: string,
        skip?: number,
        take: number = 25,
    ): CancelablePromise<BasketsGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programs/{programId}/Baskets',
            path: {
                'programId': programId,
            },
            query: {
                'skip': skip,
                'take': take,
            },
        });
    }

    /**
     * @param programId
     * @param skip
     * @param take
     * @returns ProgramRulesGetResponse OK
     * @throws ApiError
     */
    public static getRules(
        programId: string,
        skip?: number,
        take: number = 25,
    ): CancelablePromise<ProgramRulesGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programs/{programId}/ProgramRules',
            path: {
                'programId': programId,
            },
            query: {
                'skip': skip,
                'take': take,
            },
        });
    }

    /**
     * @param programId
     * @param skip
     * @param take
     * @returns ManagersGetResponse OK
     * @throws ApiError
     */
    public static getManagers(
        programId: string,
        skip?: number,
        take: number = 5,
    ): CancelablePromise<ManagersGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programs/{programId}/Managers',
            path: {
                'programId': programId,
            },
            query: {
                'skip': skip,
                'take': take,
            },
        });
    }

}
