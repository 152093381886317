import FiestaInfiniteSpinner from 'src/shared/components/infinite-spinner/FiestaInfiniteSpinner';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { ProgramsGetDetailResponse, ProgramsGetDetailResponse_CProgram, ProgramsService, ProgramStatisticsGetPointsResponse, RewardsGetRequest_CReward, RewardsGetResponse, ShopEntryStatus, ShopsGetDetailResponse, ShopsGetDetailResponse_CShop, ShopsService } from 'src/apis/api-client';
import { FiestaInfoMessage } from 'src/shared';
import { RewardListCard } from './components';
import FiestaCountdown from 'src/shared/components/countdown/FiestaCountdown';

export type RewardsListProps = { 
  programId: string;
  showCountdown?: boolean;
}

export function RewardsList(props: RewardsListProps) {
  
  /* Import variables from props. */  
  const { programId, showCountdown } = props;

  const [ loading, setLoading ] = useState<boolean>(false);
  const [ program, setProgram ] = useState<ProgramsGetDetailResponse_CProgram>();
  const [ shop, setShop ] = useState<ShopsGetDetailResponse_CShop>();
  const [ rewards, setRewards ] = useState<RewardsGetRequest_CReward[]>();
  const [ userAvailablePoints, setUserAvailablePoints ] = useState<number>();

  /**
   * Loads all needed objects initially.
   */
  useEffect(
    () => {
      (
        async () => {
          setLoading(true);

          const program = await fetchProgramDetails();

          if (program) {
            setProgram(program);
            const shop = await fetchShop(program);
            if (shop) {
              setShop(shop);

              if (shop.status == ShopEntryStatus.SHOP_OPENED) {
                const resultPoints =
                  await fetchAvailablePoints();
                if (resultPoints)
                  setUserAvailablePoints(resultPoints);

                const result =
                  await fetchRewards(shop);
                if (result)
                  setRewards(result);
              }
            }
          }

          setLoading(false);
        }
      )();
    },
    [ programId ]
  );

  /**
   * Fetches the program details.
   * @returns The result of the fetch operation.
   */
  async function fetchProgramDetails() {
    const response : ProgramsGetDetailResponse =
      await ProgramsService.getDetail(programId);

    if (response && response.data) {
      return response.data;
    }
  }

  /**
   * Fetches the available points for the user.
   * @returns The result of the fetch operation.
   */
  async function fetchAvailablePoints() {
    const response : ProgramStatisticsGetPointsResponse =
      await ProgramsService.getPoints(programId);

    if (response && response.availablePoints != null) {
      return response.availablePoints;
    }
  }

  /**
   * Fetches the shop for the given program.
   * @param forProgram The program to fetch the shop for.
   * @returns The result of the fetch operation.
   */
  async function fetchShop(forProgram: ProgramsGetDetailResponse_CProgram) {
    if (!forProgram.shopId)
      return;

    const response : ShopsGetDetailResponse =
      await ShopsService
        .getDetail(forProgram?.shopId)
          .finally(
            () => {
              return;
            }
          );

    if (response && response.data) {
      return response.data;
    }
  }

  /**
   * Fetches the rewards for the given shop.
   * @param forShop The shop to fetch rewards for.
   * @returns The result of the fetch operation.
   */
  async function fetchRewards(forShop: ShopsGetDetailResponse_CShop) {
    if (!forShop.id)
      return;

    const response : RewardsGetResponse =
      await ShopsService.getRewards(forShop.id, 0, 25);

    if (response && response.result) {
      return response.result;
    }
  }

  /**
   * Renders the rewards list, using RewardListCard.
   * @returns A div with all rewards rendered.
   */
  function renderRewards() {
    return <>
      {
        rewards
        && rewards.length > 0
        && rewards.map(
          (reward) => {
            if (reward.id)
              return <div className='col-12 md:col-6 lg:col-4'>
                <RewardListCard
                  rewardId={reward.id}
                  userAvailablePoints={userAvailablePoints ?? 0}
                />
              </div>
          }
        )
      }
    </>;
  }

  /**
   * Renders the message, if the shop is not available at the moment.
   * @returns Message that has been rendered.
   */
  function renderMessage() {
    switch (shop?.status) {
      case ShopEntryStatus.SHOP_CLOSED:
        return <FiestaInfoMessage 
          icon='lock'
          title={t('domains.programs.views.dashboard.dashboardView.components.rewards-list.closedMessage.closed.title')}
          textContent={t('domains.programs.views.dashboard.dashboardView.components.rewards-list.closedMessage.closed.text')}
        />;
      case ShopEntryStatus.SHOP_NOT_PUBLISHED:
        return <FiestaInfoMessage 
          icon='lock'
          title={t('domains.programs.views.dashboard.dashboardView.components.rewards-list.closedMessage.notPublished.title')}
          textContent={t('domains.programs.views.dashboard.dashboardView.components.rewards-list.closedMessage.notPublished.text')}
        />;
      default:
        return <></>;
    }
  }

  /* Renders the countdown row. */
  function renderCountdown() {
    let startDate;
    let targetDate;

    const shopPublishDate =
      shop?.publishingDate ?
        new Date(shop?.publishingDate)
        : undefined;

    const shopClosingDate =
      shop?.closingDate ?
        new Date(shop.closingDate)
        : undefined;

    if (shopPublishDate && shopClosingDate) {
      startDate = shopPublishDate;
      targetDate = shopClosingDate;
    }

    return <>
      <div className='flex flex-column align-items-center w-full'>
        {
          startDate
          && targetDate
          &&
          <FiestaCountdown
            label={t('domains.programs.views.dashboard.dashboardView.components.rewards-list.components.rewards-list.countdown.label')}
            size='medium'
            startDate={startDate}
            targetDate={targetDate}
          />
        }
      </div>
    </>;
  }

  return (
    <div
      className='grid'
    >
      {
        !loading
        &&
        renderRewards()
      }
      <div className='col-12 flex flex-column'>
        {
          !loading
          && shop?.status != ShopEntryStatus.SHOP_OPENED
          &&
          renderMessage()
        }
        {
          loading
          &&
          <div className='flex flex-column align-items-center mt-2'>
            <FiestaInfiniteSpinner />
          </div>
        }
      </div>
    </div>
  );
}

export default RewardsList;