import { Skeleton } from 'primereact/skeleton';
import { PropsWithChildren } from 'react';

export type FiestaImageHeaderProps = {
  title: string;
  subTitle?: string;
  textContent?: string;
  logo?: string;
  image: string;
  loading: boolean;
}

export function FiestaImageHeader(props: PropsWithChildren<FiestaImageHeaderProps>) {

  /* Import variables from props. */
  const {
    title,
    subTitle,
    logo,
    image,
    loading,
    textContent
  } = props;

  /**
   * Renders the image.
   * @param width The width to apply.
   * @param logoUrl The url of the logo.
   * @param classAddition The class to add.
   * @returns Rendered image block.
   */
  function renderImage(
    width: number,
    logoUrl: string,
    classAddition: string
  ) {
    return <>
      <div
        style={
          {
            width: `${width}px`,
            height: `${width}px`,
            backgroundColor: 'white'
          }
        }
        className={`border-round-lg shadow-6 ${classAddition}`}
      >
        <img
          src={logoUrl}
          width={`${width}px`}
          height={`${width}px`}
          style={{ backgroundColor: 'white' }}
          className='border-round-lg'
        />
      </div>
    </>;
  }

  return (
    <div
      className='surface-card'
    >
      {
        !loading
          ?
          <div
            style={
              {
                height: '250px',
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75),rgba(0, 0, 0, 0.5)),url(${image})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }
            }
            className='flex flex-column justify-content-center'
          >
            {
              logo
              &&
              <div
                className='flex flex-row pl-3 pr-3'
              >
                <div className='flex flex-column'>
                  {
                    renderImage(148, logo, 'hidden md:block')
                  }
                  {
                    renderImage(100, logo, 'block md:hidden')
                  }
                </div>
                <div className='flex flex-column pl-2 justify-content-center'>
                  {/* Render the "title" */}
                  {
                    !loading
                      ? <div className="flex m-0 text-900 text-3xl font-medium mb-0">
                        {
                          title
                        }
                      </div>
                      : <Skeleton width='300px' height='32px' />
                  }

                  {/* Render the "subTitle" */}
                  {
                    !loading
                      ? <>
                        {
                          subTitle
                          &&
                          <div className="text-600 font-medium">
                            <span>
                              {
                                subTitle
                              }
                            </span>
                          </div>
                        }
                      </>
                      : <Skeleton width='250px' height='18px' className='mt-2' />
                  }

                  {/* Render the "textContent" */}
                  {
                    !loading
                      ? <>
                        {
                          textContent
                          &&
                          <div className="text-600 text-sm pt-2">
                            <span>
                              {
                                textContent
                              }
                            </span>
                          </div>
                        }
                      </>
                      : <Skeleton width='250px' height='14px' className='mt-2' />
                  }
                </div>
              </div>
            }
          </div>
          :
          <div
            style={
              {
                height: '200px'
              }
            }
          />
      }
    </div>
  );
}
