/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventsGetDetailResponse } from '../models/EventsGetDetailResponse';
import type { EventsGetResponse } from '../models/EventsGetResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EventsService {

    /**
     * @param skip
     * @param take
     * @returns EventsGetResponse OK
     * @throws ApiError
     */
    public static get(
        skip?: number,
        take: number = 25,
    ): CancelablePromise<EventsGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Events',
            query: {
                'skip': skip,
                'take': take,
            },
        });
    }

    /**
     * @param id
     * @returns EventsGetDetailResponse OK
     * @throws ApiError
     */
    public static getDetail(
        id: string,
    ): CancelablePromise<EventsGetDetailResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Events/{id}',
            path: {
                'id': id,
            },
        });
    }

}
