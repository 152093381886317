import { useEffect, useState } from "react";

import spinner from '../../../assets/images/fiesta-spinner.gif'

export type FiestaInfiniteSpinnerProps = {
  /* The size of the spinner. */
  size?: 'small' | 'medium' | 'large';
}

export default function FiestaInfiniteSpinner(props: FiestaInfiniteSpinnerProps) {
  // Import variables from props.
  const { size } = props;

  // Set the default width and height.
  const [ width, setWidth ] = useState<number>(128);
  const [ height, setHeight ] = useState<number>(128);

  // Set the width and height based on the size.
  useEffect(
    () => {
      switch (size) {
        case 'small':
          setWidth(64);
          setHeight(64);
          break;
        case 'large':
          setWidth(256);
          setHeight(256);
          break;
        case 'medium':
        default:
          setWidth(128);
          setHeight(128);
      }
    },
    [ size ]
  );

  return (
    <>
      <img
        width={width}
        height={height}
        src={spinner}
      />
    </>
  );
}