import i18next, { t } from 'i18next';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FiestaInfiniteSpinner from 'src/shared/components/infinite-spinner/FiestaInfiniteSpinner';
import { ProgramsGetResponse, ProgramsService } from '../../../../apis/api-client';
import { FiestaBasicHeader, FiestaInfoMessage } from '../../../../shared';
import { ProgramReferralsCard } from '../../components/program-referrals-card';

export function ProgramsView() {

  /* Store the programs for the discover view. */
  const [ programs, setPrograms ] = useState<{ id?: string }[]>();

  const navigate = useNavigate();

  /* As soon as this component has been loaded, load the currently available programs. */
  useEffect(
    () => {
      (
        async () => {
          const response : ProgramsGetResponse 
            = await ProgramsService.get(0, 25, undefined, true);
          
          if (
            response && response.result
          ) {
            setPrograms(response.result);
          }
        }
      )();
    },
    []
  );

	return (
    <div>
      <FiestaBasicHeader
        title={t('domains.programs.views.programs.programsView.title')}
        subTitle={
          programs
            ? i18next.t('common.activeText', { value: programs.length })
            : ''
        }
        textContent={t('domains.programs.views.programs.programsView.textContent')}
      />
      <div className='grid pr-4 pl-4 lg:pr-0 lg:pl-0'>
        {
          programs?.map(
            (value) => 
              value.id 
              &&
              <div className='col-12 md:col-6'>
                <ProgramReferralsCard
                  id={value.id}
                  key={value.id}
                />
              </div>
          )
        }
      </div>
      {
        programs
        && programs.length == 0
        &&  
        <FiestaInfoMessage
          title={t('domains.programs.views.programs.programsView.emptyMessage.header', { interpolation: { escapeValue: false } })}
          textContent={t('domains.programs.views.programs.programsView.emptyMessage.textContent')}
        >
          <Button
            className='flex'
            label={
              t('domains.programs.views.programs.programsView.emptyMessage.buttonText')
            }
            style={
              {
                maxWidth: '300px'
              }
            }
            onClick={
              () => navigate('/discover')
            }
          />
        </FiestaInfoMessage>
      }
      {
        programs == null
        &&
        <div className='flex flex-column align-items-center pt-4'>
          <FiestaInfiniteSpinner />
        </div>
      }
    </div>
	);
}

export default ProgramsView;