import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import { CRewardMetadata } from 'src/apis/api-client';
import { MetadataContent_MetadataValue } from '../../MetadataContent';

export type MetadataTextFieldProps = {
  rewardMetadata: CRewardMetadata;
  onValue(value: MetadataContent_MetadataValue): void;
}

export function MetadataTextField(props: MetadataTextFieldProps) {
  // Import from props.
  const { rewardMetadata, onValue } = props;
  const [ inputValue, setStringValue ] = useState<string>();

  /**
   * Updates every time, the inputValue has changed.
   * This will also communicate to the parent onValue method.
   */
  useEffect(
    () => {
      if (!rewardMetadata.id)
        return;
    
      const newValue = {
        id: rewardMetadata.id,
        value: inputValue ?? '',
        valid: checkValidity(inputValue ?? ''),
        optional: rewardMetadata.isOptional == true
      };

      if (newValue)
        onValue(newValue);
    },
    [inputValue]
  );

  /**
   * Checks the validity of the object, depending on the given validator in the
   * RewardMetadata object. If the value is not valid, false will be given back.
   * @param value Value to check.
   * @returns 'true' if valid, 'false' if not.
   */
  function checkValidity(value: string) {
    if (value == null || value == '')
      return false;

    if (rewardMetadata.data) {
      const validator = JSON.parse(rewardMetadata.data);
      if (validator) {
        switch (validator.validator) {
          case 'email':
            return isValidEmail(value);
          case 'min-max':
            return isValidLength(value, validator.minLength, validator.maxLength);
          case 'telephone':
            return isValidTelephone(value);
        }
      }
    }

    return true;
  }

  /**
   * Checks, whether the input value is a valid e-mail string.
   * @param value Value to check.
   * @returns 'true' if the value is valid, 'false' if not.
   */
  function isValidEmail(value: string) {
    return new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      .test(value);
  }

  /**
   * Checks, whether the input value is a valid telephone string.
   * @param value Value to check.
   * @returns 'true' if the value is valid, 'false' if not.
   */
  function isValidTelephone(value: string) {
    return new RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g)
      .test(value);
  }

  /**
   * Checks, whether the input value has a valid length.
   * @param value Value to check.
   * @param min Minimum, inclusive amount of characters.
   * @param max Maximum, inclusive amount of characters.
   * @returns 'true' if the value is valid, 'false' if not.
   */
  function isValidLength(value: string, min?: number, max?: number) {
    if (min == null || max == null) {
      return false;
    }

    // Some users tricked the validator by using whitespaces.
    value =
      value.trim();

    return value.length >= min && value.length <= max;
  }

  return <>
    {
      rewardMetadata
      &&
      <div className='field col-12 gap-2'>
        <span className='p-float-label'>
          <InputText
            id={rewardMetadata.id}
            className='w-full'
            onChange={(e) => setStringValue(e.target.value)}
          />
          <label htmlFor='inputtext'>{ rewardMetadata.label }</label>
        </span>
        <small id={`${rewardMetadata.id}-help`} className="block">{rewardMetadata.helpText}</small>
      </div>
    }
  </>;
}