import FiestaInfiniteSpinner from 'src/shared/components/infinite-spinner/FiestaInfiniteSpinner';
import {t} from 'i18next';
import {Button} from 'primereact/button';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {FiestaReferralCard} from 'src/shared/components';
import {
  ActionsService,
  AmbassadorRegistrationResponse,
  CEvent,
  CReferralIdentifier,
  CReferralMode,
  EventsGetDetailResponse,
  EventsService,
  PrecheckResultCode,
  ProgramsGetDetailResponse,
  ProgramsGetDetailResponse_CProgram,
  ProgramsService
} from 'src/apis/api-client';
import {Carousel} from 'primereact/carousel';
import {tt} from "src/shared";

export function JoinView() {
  /* Using params, as we are expecting the program id. */
  const routeParams = useParams();
  const navigate = useNavigate();

  const [event, setEvent] = useState<CEvent>();
  const [loading, setLoading] = useState<boolean>(true);
  const [program, setProgram] = useState<ProgramsGetDetailResponse_CProgram>();
  const [referrals, setReferrals] = useState<CReferralIdentifier[]>();

  /* Fetch the data for the program and event. */
  useEffect(
    () => {
      (
        async () => {
          if (!routeParams.programId) return;

          setLoading(true);

          // Precheck, if the ambassador can register.
          const preCheckResponse =
            await ActionsService.registerAmbassadorPrecheck(routeParams.programId)
              .catch(() => enterPrograms());

          if (
            preCheckResponse
            && preCheckResponse.data
          ) {
            if (preCheckResponse.data.registrationPossible) {
              const programResponse: ProgramsGetDetailResponse
                = await ProgramsService.getDetail(routeParams.programId);

              if (
                programResponse && programResponse.data
              ) {
                setProgram(programResponse.data);

                if (programResponse.data.eventId) {
                  if (programResponse && programResponse.data && programResponse.data.id) {
                    const regResponse: AmbassadorRegistrationResponse =
                      await ActionsService.registerAmbassador(programResponse.data?.id);

                    if (programResponse.data.mode === CReferralMode.BASKET)
                      enterProgram(programResponse.data?.id);

                    if (regResponse && regResponse.data) {
                      setReferrals(regResponse.data);
                    }
                  }
                }
              }
            } else {
              if (preCheckResponse.data.resultCode == PrecheckResultCode.ALREADY_REGISTERED) {
                enterProgram(routeParams.programId);
              } else {
                navigate('/programs/sorry/' + preCheckResponse.data.resultCode);
              }
            }
          } else {
            enterPrograms();
          }

          setLoading(false);
        }
      )();
    },
    [routeParams.programId]
  );

  /**
   * Redirect to the discover view.
   */
  function enterPrograms() {
    navigate('/discover');
  }

  /**
   * Redirect to the program dashboard.
   * @param programId The program id.
   */
  function enterProgram(programId: string) {
    navigate('/programs/' + programId + '/dashboard?fresh=true');
  }

  /**
   * Render the referrals.
   * @param referrals The referrals to render.
   * @returns The rendered referrals.
   */
  function renderReferrals(referrals: CReferralIdentifier[]) {
    return <>
      <Carousel
        className='w-full'
        value={referrals}
        showIndicators={false}
        itemTemplate={
          (referral: CReferralIdentifier) => <FiestaReferralCard data={referral}/>
        }
        showNavigators={referrals.length > 1}
      />
    </>;
  }

  return (
    <>
      <div className='grid p-4 mt-4'>
        {
          referrals != null
          &&
          <div className='lg:col-offset-3 col-12 lg:col-6'>
            {
              program?.referralInfo
              && program.id
              &&
              <div
                  className='flex flex-column pl-4 pr-4 text-center'
                  dangerouslySetInnerHTML={{
                    __html: tt(program.id, 'program_referralInfo', program.referralInfo)
                  }}
              >
              </div>
            }
              <div className='flex flex-row flex-wrap gap-2 p-4'>
                {
                  !referrals
                    ? <></>
                    : renderReferrals(referrals)
                }
              </div>
              <div className='flex flex-column p-4'>
                {
                  program
                  &&
                    <Button
                        label={
                          t('domains.programs.views.join.joinView.buttons.enter')
                        }
                        onClick={() => enterProgram(program.id ?? '')}
                    />
                }
              </div>
          </div>
        }
        {
          (
            referrals == null
            || loading
          )
          &&
          <div className='col-12'>
              <div className='flex flex-column align-items-center'>
                  <FiestaInfiniteSpinner/>
              </div>
          </div>
        }
      </div>
    </>
  );
}
