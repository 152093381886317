import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export function FlowsManager() {
  /* Import variables from props. */
  const navigate = useNavigate();
  const param = useParams();

  /* Immediately redirect-utils. */
  useEffect(
    () => {
      const { flowType, id } = param;

      // If the flowType is pdj (Program Direct Join), we will navigate to the join immediately.
      if (flowType === 'pdj') {
        navigate(`/programs/${id}/join`); return;
      }

      navigate('/discover');
    },
    []
  )

  return <></>;
}

export default FlowsManager;