/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CLandingpageColorKey {
    HEADER_TEXT = 'HeaderText',
    HEADER_BUTTON = 'HeaderButton',
    FACTS_BACKGROUND = 'FactsBackground',
    FACTS_ICON = 'FactsIcon',
    FACTS_TEXT = 'FactsText',
    CTA_BACKGROUND = 'CtaBackground',
    CTA_TEXT = 'CtaText',
    CTA_BUTTON = 'CtaButton',
    CTA_SUBTEXT = 'CtaSubtext',
    FOOTER_BACKGROUND = 'FooterBackground',
    FOOTER_TEXT = 'FooterText',
}
