/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AmbassadorRegistrationPrecheckResponse } from '../models/AmbassadorRegistrationPrecheckResponse';
import type { AmbassadorRegistrationResponse } from '../models/AmbassadorRegistrationResponse';
import type { PurchaseRewardRequest } from '../models/PurchaseRewardRequest';
import type { PurchaseRewardResponse } from '../models/PurchaseRewardResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ActionsService {

    /**
     * @param programId
     * @returns AmbassadorRegistrationResponse OK
     * @throws ApiError
     */
    public static registerAmbassador(
        programId: string,
    ): CancelablePromise<AmbassadorRegistrationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/RegisterAmbassador/{programId}',
            path: {
                'programId': programId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns PurchaseRewardResponse OK
     * @throws ApiError
     */
    public static purchaseReward(
        requestBody?: PurchaseRewardRequest,
    ): CancelablePromise<PurchaseRewardResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/PurchaseReward',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param programId
     * @returns AmbassadorRegistrationPrecheckResponse OK
     * @throws ApiError
     */
    public static registerAmbassadorPrecheck(
        programId: string,
    ): CancelablePromise<AmbassadorRegistrationPrecheckResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/RegisterAmbassadorPrecheck/{programId}',
            path: {
                'programId': programId,
            },
        });
    }

}
